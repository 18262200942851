import React from "react";
import Label from "../../../../../components/ui/label/Label";
import Input from "../../../../../components/ui/inputs/input/Input";
import "./SyncOrderDetails.scss";
import CustomDropdown from "../../../../../components/ui/customdropdown/CustomDropdown";
import Iconify from "../../../../../components/ui/iconify/Iconify";
import Buttons from "../../../../../components/ui/button/Buttons";
import NoRecordFound from "../../../../../components/ui/noRecordFound/NoRecordFound";

const SyncOrderDetails = () => {
  const orderBasicDetails = [
    {
      label: "Customer Name",
      placeholder: "Select Customer Name",
      type: "dropdown",
      flag: true,
      value: "Praful Desai",
    },
    {
      label: "PO Number",
      placeholder: "Enter PO Number",
      type: "text",
      flag: true,
    },
    {
      label: "Order Method",
      placeholder: "Select Order Method",
      type: "dropdown",
      flag: true,
      value: "Online",
    },
    {
      label: "Order Received Date",
      placeholder: "Enter Order Received Date",
      type: "text",
      flag: true,
    },
    {
      label: "Billing Address",
      placeholder: "Select Billing Address",
      type: "dropdown",
      flag: true,
    },
    {
      label: "Shipping Address",
      placeholder: "Select Shipping Address",
      type: "dropdown",
      flag: true,
      value: "123 Street, City",
    },
  ];

  const orderContactDetails = [
    {
      label: "End User",
      placeholder: "Select End User",
      type: "dropdown",
      flag: true,
    },
    {
      label: "Invoice Submission",
      placeholder: "Select Invoice Submission",
      type: "dropdown",
      flag: true,
      value: "Pending",
    },
    {
      label: "Purchasing",
      placeholder: "Select Purchasing",
      type: "dropdown",
      flag: true,
      value: "Direct Purchase",
    },
  ];

  // Render fields with `flag: true`
  const renderFields = (fields) =>
    fields
      .filter((field) => field.flag)
      .map((field, index) => (
        <div className="col-4" key={index}>
          <div className="form-field">
            <div className="input-label-part">
              <Label labelName={field.label} />
              {field.type === "dropdown" ? (
                <CustomDropdown placeholder={field.placeholder} />
              ) : (
                <Input name={field.label} type={field.type} placeholder={field.placeholder} />
              )}
            </div>
          </div>
        </div>
      ));

  // Render mismatched fields with `flag: false`
  const renderMismatchedFields = (fields) =>
    fields
      .filter((field) => !field.flag)
      .map((field, index) => (
        <div className="col-4" key={index}>
          <div className="approval-container">
            <div className="approval-formfield">
              <div className="approval-form-label">
                <Iconify icon="charm:cross" />
                {field.label} : {field.value}
              </div>
            </div>
            <div className="approval-btn">
              <Buttons buttonTypeClassName="theme-button" buttonText="Add" />
            </div>
          </div>
        </div>
      ));

  // Check if there are any mismatched fields
  const hasMismatchedFields =
    orderBasicDetails.some((field) => !field.flag) ||
    orderContactDetails.some((field) => !field.flag);

  return (
    <React.Fragment>
      <div className="order-sync-form">
        {/* Order Basic Details */}
        <div className="order-info">
          <div className="order-title">Order Basic Details</div>
          <div className="order-formfield">
            <div className="row">{renderFields(orderBasicDetails)}</div>
          </div>
        </div>

        {/* Order Contact Details */}
        <div className="order-info mt-2">
          <div className="order-title">Order Contact Details</div>
          <div className="order-formfield">
            <div className="row">{renderFields(orderContactDetails)}</div>
          </div>
        </div>

        {/* Mismatched Field Approval */}
        <div className="approval-info mt-2">
          <div className="approval-info-header">Mismatched Field Approval</div>
          <div className="approval-info-body">
            {hasMismatchedFields ? (
              <div className="row">
                {renderMismatchedFields(orderBasicDetails)}
                {renderMismatchedFields(orderContactDetails)}
              </div>
            ) : (
              <div className="no-mismatch-found">
                <NoRecordFound message="No Mismatch Found"/>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SyncOrderDetails;
