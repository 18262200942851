/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useGetUndefinedOrdersEmailsByIdMutation } from "../../../app/services/orderAPI";
import "./UndefinedOrder.scss";
import formatDate from "../../../lib/formatDate";
import EmailFormate from "./EmailFormate";
import DataLoader from "../../../components/ui/dataLoader/DataLoader";

const UndefinedOrderDetail = ({ emailId }) => {
  const [orderdDataObj, setOrderDataObj] = useState(null);
  const [
    getUndefinedOrdersEmailsById,
    {
      isLoading,
      isFetching: isGetUndefinedOrdersEmailsByIdFetching,
      isSuccess: isGetUndefinedOrdersEmailsByIdSuccess,
      data: isGetUndefinedOrdersEmailsByIdData,
    },
  ] = useGetUndefinedOrdersEmailsByIdMutation();

  useEffect(() => {
    
    if (emailId) {
      getUndefinedOrdersEmailsById(emailId);
    }
  }, [emailId]);

  useEffect(() => {
    
    if (!isGetUndefinedOrdersEmailsByIdFetching && isGetUndefinedOrdersEmailsByIdSuccess && isGetUndefinedOrdersEmailsByIdData) {
      if (isGetUndefinedOrdersEmailsByIdData) {
        setOrderDataObj(isGetUndefinedOrdersEmailsByIdData)
      }
    }
  }, [isGetUndefinedOrdersEmailsByIdFetching, isGetUndefinedOrdersEmailsByIdSuccess, isGetUndefinedOrdersEmailsByIdData]);

  return (<>
 { !isLoading ? (
        <div className="email-detail pb-0">
            <span className="mb-0">
              <div className="header-info">
                <div className="col-md-8 email-item-info">
                  <div className="subject-title">
                    <span className="label">Subject: </span>
                    <span className="value">{orderdDataObj?.subject}</span>
                  </div>
                  <div className="subject-title">
                    <span className="label">From: </span>
                    <span className="value">{orderdDataObj?.fromEmail}</span>
                  </div>
                  <div className="subject-title">
                    <span className="label">To: </span>
                    <span className="value">{orderdDataObj?.toEmail}</span>
                  </div>
                </div>
                <div className="col-md-4 email-date">
                  <span>{formatDate(orderdDataObj?.create_date)}</span>
                </div>
              </div>
              <div className="mb-3">
                <span className="label">Body: </span>
                <EmailFormate EmailState={orderdDataObj && orderdDataObj?.body} />
              </div>
            </span> 
        </div>
    ) : (
      <DataLoader />
    )}
  </>
  );
}
export default UndefinedOrderDetail;