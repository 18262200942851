import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./SwalService.scss";
import { AppIcons } from "../../data/appIcons";

export const SwalServices = withReactContent(Swal);

const customClass = {
  container: "",
  popup: "",
  header: "",
  title: "",
  closeButton: "",
  icon: "",
  image: "",
  htmlContainer: "",
  input: "",
  inputLabel: "",
  validationMessage: "",
  actions: "",
  confirmButton: "btn theme-button",
  denyButton: "btn dark-btn",
  cancelButton: "btn dark-btn",
  loader: "",
  footer: "",
  timerProgressBar: "",
};
const SwalAlert = () => {
  const success = (text, title) => {
    return SwalServices.fire({
      title: title,
      text: text,
      icon: "success",
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: true,
    });
  };

  const error = (text, title) => {
    return SwalServices.fire({
      title: title,
      text: text,
      icon: "error",
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: true,
    });
  };

  const warning = (title, text) => {
    return SwalServices.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: true,
    });
  };

  const info = (title, text) => {
    return SwalServices.fire({
      title: title,
      text: text,
      icon: "info",
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: true,
    });
  };

  const confirm = (
    title,
    text,
    saveBtnText,
    cancelBtnText,
    isCancelButton,
    validationMessage,
    validateCallback,
    placeholderText,
    includeTextarea = false
  ) => {
    const textareaHtml = includeTextarea ? `
      <textarea id="swal-textarea" class="swal-textarea" placeholder="${placeholderText}" style="width:100%; height:100px; margin-top:10px;"></textarea>
      <div id="validation-message" class="swal-validation-error" style="display: none;">${validationMessage}</div>
    ` : '';
  
    return SwalServices.fire({
      title: title,
      html: `
        <p>${text}</p>
        ${textareaHtml}  <!-- Include the textarea HTML only if includeTextarea is true -->
      `,
      icon: "question",
      showCancelButton: isCancelButton === false ? isCancelButton : true,
      confirmButtonText: saveBtnText,
      cancelButtonText: cancelBtnText,
      showCloseButton: false,
      allowOutsideClick: false,
      allowEscapeKey: true,
      customClass: customClass,
      preConfirm: () => {
        if (includeTextarea) {
          const textareaValue = document.getElementById("swal-textarea").value.trim();

          const isValid = validateCallback(textareaValue);
          if (!isValid) {
            document.getElementById("validation-message").style.display = "block";
            return false;
          }
          return textareaValue;
        }
        return true;
      }
    }).then((result) => {
      if (result.dismiss === SwalServices.DismissReason.cancel) {
        return false;
      }
  
      if (result.value) {
        return result.value;
      }
  
      return false;
    });
  };
  
  const blocked = (title, text, iconUrl) => {
    return SwalServices.fire({
      title: title,
      text: text,
      iconHtml: `<img src="${AppIcons.BlockedIcon}" class="swal-icon"/>`,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: true,
      customClass: customClass,
    });
  };

  // Export the SwalService functions
  return { confirm, success, warning, info, error, blocked };
};

export default SwalAlert;
