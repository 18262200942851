/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useState } from "react";
import CardSection from "../../../../../components/ui/card/CardSection";
import { OrderStatusEnums } from "../../../../../utils/Enums/StatusEnums";
import PendingOrderList from "./PendingOrderList";


const PendingActionList = () => {

  const [activeTab, setActiveTab] = useState("0");
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex.toString());
  };

  // const CustomerSubStatus = [
  //   OrderSubStatusBadgeEnum.PendingCustomer,
  //   OrderSubStatusBadgeEnum.PaymentTermsNotMatching,
  //   OrderSubStatusBadgeEnum.PONumberIsWrong,
  //   OrderSubStatusBadgeEnum.CreditLimitCrossed,
  //   OrderSubStatusBadgeEnum.BillingAddressMismatch,
  //   OrderSubStatusBadgeEnum.InvoiceEmailMismatch,
  //   OrderSubStatusBadgeEnum.Incoterms,
  //   OrderSubStatusBadgeEnum.PastDue

  // ].join(",");

  // const ItemSubStatus = [
  //   OrderSubStatusBadgeEnum.Replacement,
  //   OrderSubStatusBadgeEnum.LeadTime,
  //   OrderSubStatusBadgeEnum.StockVerification,
  //   OrderSubStatusBadgeEnum.PriceMismatch
  // ].join(",");

  const tabs = [
    {
      sMenuItemCaption: "BY CUSTOMER",
      component: (
        <div className="mt-2">
          <PendingOrderList
            // orderSubStatusId={CustomerSubStatus}
            orderStatusId ={OrderStatusEnums.PENDINGACTION}
            isCustomer={1}
          />
        </div>
      ),
    },
    {
      sMenuItemCaption: "BY ITEMS",
      component: (
        <div className="mt-2">
          <PendingOrderList
            orderStatusId ={OrderStatusEnums.PENDINGACTION}
             isCustomer={0}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="main-inactive-grid">
      <div className="row">
        <div className="col-xxl-12 col-xl-12 col-md-12 col-12 other-info-tab">
          <CardSection
          //   cardTitle="Other Information"
          >
            {tabs && tabs.length > 0 && (
              <div className="row">
                <div className="col-12">
                  <div className="tab-sub-section mb-0">
                    <div className="tab-sub-header">
                      {tabs &&
                        tabs.map((tab, index) => (
                          <button
                            key={index}
                            className={
                              activeTab === index.toString() ? "active" : ""
                            }
                            onClick={() => handleTabClick(index, tab.sPage)}
                          >
                            {tab.sMenuItemCaption}
                          </button>
                        ))}
                    </div>
                    {activeTab !== -1 && tabs[activeTab].component && (
                      <div className="tab-sub-content">
                        <div className="tab-sub-body-section">
                          {tabs[activeTab].component}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </CardSection>
        </div>
      </div>
    </div>
  );
}
PendingActionList.propTypes = {
  initialTab: PropTypes.string,
};
export default PendingActionList;