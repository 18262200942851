
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../utils/API/responseMiddleware";

const IntentEmailAPI = createApi({
    reducerPath: "IntentEmailAPI",
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        getEmailInboxList: builder.mutation({
            query: (userQuery) => ({
                url: '/EmailInbox/GetEmailInboxList',
                method: 'POST',
                body: transformRequest(userQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getEmailInboxDetailsById: builder.mutation({
            query: (mongoDbEmailId) => ({
                url: `/EmailInbox/GetEmailInboxDetailsById/?mailid=${mongoDbEmailId}`,
                method: 'POST',

            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        updateEmailInboxFlaggedByEmailId: builder.mutation({
            query: (detail) => ({
                url: `/EmailInbox/UpdateEmailInboxFlaggedByEmailId`,
                method: 'POST',
                body: transformRequest(detail)

            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        updateEmailIntent: builder.mutation({
            query: (requestData) => ({
                url: `/EmailInbox/UpdateEmailIntent`,
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getAllEmailActions: builder.query({
            query: () => ({
                url: "/Common/GetAllEmailActions",
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getEmailCommentsByMongoDbEmailId: builder.query({
            query: (mongoDbEmailId) => ({
                url: `/EmailInbox/GetEmailCommentsByMongoDbEmailId/?mongoDbEmailId=${mongoDbEmailId}`,
                method: 'GET',

            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        addEmailComments: builder.mutation({
            query: (requestData) => ({
                url: `/EmailInbox/AddEmailComments`,
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getEmailTemplateByEmailActionId: builder.query({
            query: (emailActionId) => ({
                url: encryptQueryString(`/EmailTemplates/GetEmailTemplateByEmailActionId/?emailActionId=${Number(emailActionId)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getEmailInboxDetailsMongoDbEmailId: builder.query({
            query: (mongoDbEmailId) => ({
                url: encryptQueryString(`/EmailInbox/GetEmailInboxDetailsMongoDbEmailId/?mongoDbEmailId=${mongoDbEmailId}`),
                method: 'GET'
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        updateLinkCustomerId: builder.mutation({
            query: (requestData) => ({
                url: `/EmailInbox/UpdateLinkCustomerId`,
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getEmailTimeLineByMongoDbEmailId: builder.query({
            query: (mongoDbEmailId) => ({
                url: `/EmailInbox/GetEmailTimeLineByMongoDbEmailId/?mongoDbEmailId=${mongoDbEmailId}`,
                method: 'GET',

            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
       
    })
})

export const {
    useGetEmailInboxListMutation, useGetEmailInboxDetailsByIdMutation, useUpdateEmailInboxFlaggedByEmailIdMutation, useLazyGetAllEmailActionsQuery,
    useUpdateEmailIntentMutation, useLazyGetEmailCommentsByMongoDbEmailIdQuery,  useAddEmailCommentsMutation, useLazyGetEmailTemplateByEmailActionIdQuery,
    useLazyGetEmailInboxDetailsMongoDbEmailIdQuery, useUpdateLinkCustomerIdMutation,useLazyGetEmailTimeLineByMongoDbEmailIdQuery,
} = IntentEmailAPI

export default IntentEmailAPI;