const validateRequired = (value, message) => {
    return value === undefined || value === null || String(value).trim() === '' ? message : null;
};

const validateMinLength = (value, minLength, message) => {
    return value.length < minLength ? message : null;
};

const validateMaxLength = (value, maxLength, message) => {
    return value.length > maxLength ? message : null;
};

const validateMin = (value, minValue, message) => {
    return value < minValue ? message : null;
};
const validateDecimal = (value, message) => {
    const decimalPattern = /^\d{1,15}(\.\d{0,2})?$/;
    return !decimalPattern.test(value) ? message : null;
};
const validateMaxDigits = (value, maxDigits, message) => {
    const integerPart = value.split('.')[0];
    return integerPart.length > maxDigits ? message : null;
};



export const validateField = (value, validations) => {
    for (let validation of validations) {
        switch (validation.type) {
            case 'required':
                const requiredError = validateRequired(value, validation.message);
                if (requiredError) return requiredError;
                break;
            case 'minLength':
                const minLengthError = validateMinLength(value, validation.message);
                if (minLengthError) return minLengthError;
                break;
            case 'maxLength':
                const maxLengthError = validateMaxLength(value, validation.value, validation.message);
                if (maxLengthError) return maxLengthError;
                break;
            case 'min':
                const minError = validateMin(value, validation.value, validation.message);
                if (minError) return minError;
                break;
            case 'decimal':
                const decimalError = validateDecimal(value, validation.message);
                if (decimalError) return decimalError;
                break;
            case 'maxDigits':
                const maxDigitsError = validateMaxDigits(value, validation.value, validation.message);
                if (maxDigitsError) return maxDigitsError;
                break;
            // Add more validation types as needed
            default:
                break;
        }
    }
    return null;
}


export const validateColumns = (columns, newRowData) => {
    const newErrors = {};

    columns.forEach(col => {
        if (col.allowEditColumn && Array.isArray(col.editColumn.editColValidation) && col.editColumn.editColValidation.length > 0) {
            const value = newRowData[col.fieldName];
            const error = validateField(value, col.editColumn.editColValidation);
            if (error) {
                newErrors[col.fieldName] = error;
            }
        }
    });

    return newErrors;
};