import React from "react";

const EmailFormate = ({
  EmailState
}) => {

  return (
    <div className="email-body-sec">
    
      <div dangerouslySetInnerHTML={{ __html: EmailState }} />
    </div>
  );
};

export default EmailFormate;
