

import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../../utils/API/fetchBaseQuery";
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../utils/API/responseMiddleware";


const potentialCustomerAPI = createApi({
    reducerPath: 'potentialCustomerAPI',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        addPotentialCustomer: builder.mutation({
            query: (requestData) => ({
                url: `/PotentialCustomer/AddPotentialCustomer`,
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getPotentialCustomer : builder.mutation({
            query: (userQuery) => ({
                url: '/PotentialCustomer/GetPotentialCustomer',
                method: 'POST',
                body: transformRequest(userQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        deletePotentialCustomer: builder.mutation({
            query: (potentialCustomerId) => ({
                url: encryptQueryString(`/PotentialCustomer/DeletePotentialCustomer/?potentialCustomerId=${potentialCustomerId}`),
                method: 'DELETE',
                body: transformRequest(potentialCustomerId)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
    })
})

export const {  useGetPotentialCustomerMutation,useAddPotentialCustomerMutation,useDeletePotentialCustomerMutation,
 } = potentialCustomerAPI;

export default potentialCustomerAPI;