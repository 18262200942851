import { GridColumnType } from "../../../../../components/FinalMolGrid/libs/data/gridColumnType";

export const PendingActionCustomerList = {
  columns: [
    {
      name: "Customer Name",
      fieldName: "customerName",
      allowShort: false,
      colStyle: {
        width: "20%",
      },
    },
    {
      name: "PO",
      fieldName: "poNumber",
      allowShort: false,
      colStyle: {
        width: "15%",
      },
    },
    {
      name: "Badge",
      fieldName: "orderBadgeSubStatus",
      allowShort: false,
      colStyle: {
        width: "25%",
        display: "flex",
        justifyContent: "flex-start",
      },
      colType: GridColumnType.CUSTOM,
      renderCustomCol: (rowData) => {
        // Check if orderBadgeSubStatus exists
        const words = rowData.orderBadgeSubStatus
          ? rowData.orderBadgeSubStatus
              .split(",") // Split by commas
              .map((word) => word.trim()) // Trim spaces
          : [];

        return (
          <div>
            {words.length > 0
              ? words.map((word, index) => (
                  <span
                    key={index}
                    className={`order-list-badge ${word
                      .replace(/\s+/g, "")
                      .toLowerCase()}`}
                  >
                    {word}
                  </span>
                ))
              : "N/A"}{" "}
            {/* Display each word in a separate span with its original value */}
          </div>
        );
      },
    },
    {
      name: "Created Date",
      fieldName: "createdAt",
      colType: GridColumnType.DATE,
      colSettings: {
        format: "DD/MM/YYYY hh:mm A ",
      },
      allowShort: false,
      colStyle: {
        width: "15%",
      },
    },
    {
      name: "Created By",
      fieldName: "createdByFullName",
      allowShort: false,
      colStyle: {
        width: "10%",
      },
    },
    {
      name: "Order Progress",
      fieldName: "completionPercentage",
      allowShort: false,
      colStyle: {
        width: "15%",
      },
      colType: GridColumnType.CUSTOM,
      renderCustomCol: (rowData) => {
        return (
          <div className="order-progressbar">
            <div className="skillBarContainer">
              <div
                className="skillBar"
                style={{ width: `${rowData.completionPercentage || 0}%` }}
              ></div>
            </div>
            <div className="skillBar-Value">
              {rowData.completionPercentage
                ? `${rowData.completionPercentage}%`
                : "N/A"}
            </div>
          </div>
        );
      },
    },
    {
      name: "Action",
      colType: GridColumnType.ACTION,
      defaultAction: {
        allowEdit: true,
        allowDelete: true,
      },
      allowShort: false,
      colStyle: {
        width: "15%",
      },
      customAction: [
        {
          name: "ADDCOMMENTS",
          iconName: "la:comments",
          title: "Comments",
          className: "comments-icon",
          isActive: true,
          isShowBadge: true,
          badgeContentKey: "orderCommentsCount",
        },
        {
          name: "SHOWTIMELINE",
          iconName: "iconamoon:history-bold",
          title: "TimeLine",
          className: "comments-icon",
          isActive: true,
        },
      ],
    },
  ],
  ChildTableColumn: [
    {
      name: "Customer Details",
      fieldName: "customerDetails",
      allowShort: false,
      colStyle: {
        width: "25%",
      },
    },
    {
      name: "Order Details",
      fieldName: "orderDetails",
      allowShort: false,
      colStyle: {
        width: "25%",
      },
    },
    {
      name: "Badge",
      fieldName: "status",
      allowShort: false,
      colStyle: {
        width: "50%",
      },
      colType: GridColumnType.CUSTOM,
      renderCustomCol: (rowData) => {
        // Check if orderBadgeSubStatus exists
        return (
          <span
            className={`order-list-badge ${rowData?.status
              .replace(/\s+/g, "")
              .toLowerCase()}`}
          >
            {rowData?.status}
          </span>
        );
      },
    },
  ],

  hasChildGridTable: true,
  childGridSetting: {
    hideChildHeader: false,
  },
};

export const PendingActionItemList = {
  columns: [
    {
      name: "Customer Name",
      fieldName: "customerName",
      allowShort: false,
      colStyle: {
        width: "20%",
      },
    },
    {
      name: "PO",
      fieldName: "poNumber",
      allowShort: false,
      colStyle: {
        width: "15%",
      },
    },
    {
      name: "Badge",
      fieldName: "orderBadgeSubStatus",
      allowShort: false,
      colStyle: {
        width: "25%",
        justifyContent: "flex-start",
      },
      colType: GridColumnType.CUSTOM,
      renderCustomCol: (rowData) => {
        // Check if orderBadgeSubStatus exists
        const words = rowData.orderBadgeSubStatus
          ? rowData.orderBadgeSubStatus
              .split(",") // Split by commas
              .map((word) => word.trim()) // Trim spaces
          : [];

        return (
          <div>
            {words.length > 0
              ? words.map((word, index) => (
                  <span
                    key={index}
                    className={`order-list-badge ${word
                      .replace(/\s+/g, "")
                      .toLowerCase()}`}
                  >
                    {word}
                  </span>
                ))
              : "N/A"}{" "}
            {/* Display each word in a separate span with its original value */}
          </div>
        );
      },
    },
    {
      name: "Created Date",
      fieldName: "createdAt",
      colType: GridColumnType.DATE,
      colSettings: {
        format: "DD/MM/YYYY hh:mm A ",
      },
      allowShort: false,
      colStyle: {
        width: "15%",
      },
    },
    {
      name: "Created By",
      fieldName: "createdByFullName",
      allowShort: false,
      colStyle: {
        width: "10%",
      },
    },
    {
      name: "Order Progress",
      fieldName: "completionPercentage",
      allowShort: false,
      colStyle: {
        width: "15%",
      },
      colType: GridColumnType.CUSTOM,
      renderCustomCol: (rowData) => {
        return (
          <div className="order-progressbar">
            <div className="skillBarContainer">
              <div
                className="skillBar"
                style={{ width: `${rowData.completionPercentage || 0}%` }}
              ></div>
            </div>
            <div className="skillBar-Value">
              {rowData.completionPercentage
                ? `${rowData.completionPercentage}%`
                : "N/A"}
            </div>
          </div>
        );
      },
    },
    {
      name: "Action",
      colType: GridColumnType.ACTION,
      defaultAction: {
        allowEdit: true,
        allowDelete: true,
      },
      allowShort: false,
      colStyle: {
        width: "15%",
      },
      customAction: [
        {
          name: "ADDCOMMENTS",
          iconName: "la:comments",
          title: "Comments",
          className: "comments-icon",
          isActive: true,
          isShowBadge: true,
          badgeContentKey: "orderCommentsCount",
        },
        {
          name: "SHOWTIMELINE",
          iconName: "iconamoon:history-bold",
          title: "TimeLine",
          className: "comments-icon",
          isActive: true,
        },
      ],
    },
  ],
  ChildTableColumn: [
    {
      name: "Chemical Name",
      fieldName: "chemicalName",
      allowShort: false,
      colStyle: {
        width: "20%",
      },
    },
    {
      name: "Catalog",
      fieldName: "catalogId",
      allowShort: false,
      colStyle: {
        width: "20%",
      },
    },
    {
      name: "Cas Number",
      fieldName: "casNumber",
      allowShort: false,
      colStyle: {
        width: "20%",
      },
    },
    {
      name: "Pack Size",
      fieldName: "packSize",
      allowShort: false,
      colType: GridColumnType.CUSTOM,
      colStyle: {
        width: "20%",
      },
      renderCustomCol: (rowData) => {
        return `${rowData?.["quantity"]} X ${rowData?.["packSize"]} ${rowData?.["unit"]}`;
      },
    },
    {
      name: "Unit Price",
      fieldName: "itemUnitPrice",
      allowShort: false,
      colType: GridColumnType.CUSTOM,
      colStyle: {
        width: "20%",
      },
      renderCustomCol: (rowData) => {
        const priceValue =
          parseFloat(rowData?.["itemUnitPrice"]?.replace(/[$,]/g, "")) || 0;
        return `$ ${priceValue?.toFixed(2)}`;
      },
    },
    {
      name: "Total Price",
      fieldName: "itemUnitPrice",
      allowShort: false,
      colType: GridColumnType.CUSTOM,
      colStyle: {
        width: "20%",
      },
      renderCustomCol: (rowData) => {
        const packageValue = parseFloat(rowData?.["quantity"]) || 0;
        const priceValue =
          parseFloat(rowData?.["itemUnitPrice"]?.replace(/[$,]/g, "")) || 0; // Remove '$' and ','
        const result = packageValue * priceValue;
        return `$ ${result?.toFixed(2)}`;
      },
    },
    {
      name: "Order Badge",
      fieldName: "status",
      allowShort: false,
      colStyle: {
        width: "15%",
      },
      colType: GridColumnType.CUSTOM,
      renderCustomCol: (rowData) => {
        // Check if orderBadgeSubStatus exists
        return (
          <span
            className={`order-list-badge ${rowData?.status
              .replace(/\s+/g, "")
              .toLowerCase()}`}
          >
            {rowData?.status}
          </span>
        );
      },
    },
  ],
  allowEdit: false,
  handleRowDataUpdate: null,
  OnColumnChangeEdit: null,
  hasChildGridTable: true,
  childGridSetting: {
    hideChildHeader: false,
  },
};
