/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle, useState, useMemo } from "react";
import PropTypes from 'prop-types';

import FormFields from "./formField";
import { getValidationRules } from "../../utils/Validation/GetValidationRule";
import { ValidateAll, Validate, ValidateFileField, ValidateImageField } from "../../utils/Validation/Validation";

const FormCreator = forwardRef(({
  initialState,
  config,
  onFormDataChange,
  onColumnChange,
  onFieldBlur,
  onActionChange,
  onSubmit,
  onFieldBlure,
  ...otherProps
}, ref) => {
  const { formFields, section, formSetting, initialState: configInitialState } = config;

  const [formData, setFormData] = useState(initialState);
  const [validState, setValidState] = useState({
    isValid: true,
    error: {},
  });
  const validationRules = useMemo(() => getValidationRules(section), [section]);

  useEffect(() => {
    setFormData(configInitialState);
    setValidState({
      isValid: true,
      error: {},
    });
  }, [configInitialState]);

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      const validation = handleValidation();
      return validation.isValid ? formData : null;
    },
    getFormDataWithoutValidation: () => {
      return formData;
    }
  }), [formData, validState, validationRules]);


  const handleValidation = () => {
    const validation = ValidateAll(formData, validationRules);
    setValidState(validation);
    return validation;
  };

  const handleStateChange = (updatedData) => {
    setFormData(updatedData);
    onFormDataChange?.(updatedData);
  };

  const handleOnValidateField = (dataField) => {
    const validation = validationRules[dataField] ? Validate(formData, validationRules, dataField) : null;
    if (!validation) return;

    const validationObj = { ...validState };
    if (!validation.isValid) {
      validationObj.error[dataField] = validation.error[dataField];
      validationObj.isValid = false;
    } else {
      delete validationObj.error[dataField];
      validationObj.isValid = Object.keys(validationObj.error).length === 0;
    }
    setValidState(validationObj);
  };
  const handleFileValidation = (files, dataField, fileValidationState, updatedData) => {
    if (!files || !Array.isArray(files)) return;

    const validationObj = { ...validState, error: { ...validState.error } };

    files.forEach((file, index) => {
      const validation = validationRules[dataField]
        ? ValidateFileField(
          file.file,
          fileValidationState?.fieldValiadtionRules,
          updatedData || formData,
          fileValidationState?.acceptedFileFormats,
          fileValidationState.minFileSizeInMB,
          fileValidationState.maxFileSizeInMB,
          dataField
        )
        : null;

      if (validation && !validation.isValid) {
        validationObj.error[`${dataField}`] = validation.messages[0].message;
      } else {
        delete validationObj.error[`${dataField}`];
      }
    });

    // Update the overall `isValid` state based on error presence
    validationObj.isValid = Object.keys(validationObj.error).length === 0;
    if(validationObj.isValid){
      onFieldBlure?.(dataField, updatedData);
    }
    setValidState(validationObj);
  };

  const handleImageValidation = (files, dataField, fileValidationState, updatedData) => {
    if (!files || !Array.isArray(files)) return;

    const validationObj = { ...validState, error: { ...validState.error } };

    files.forEach((file, index) => {
      const validation = validationRules[dataField]
        ? ValidateImageField(file.file, fileValidationState?.fieldValiadtionRules, updatedData || formData, fileValidationState, file.image)
        : null;

      if (validation && !validation.isValid) {
        validationObj.error[`${dataField}`] = validation.messages[0].message;
      } else {
        delete validationObj.error[`${dataField}`];
      }
    });

    // Update the overall `isValid` state based on error presence
    validationObj.isValid = Object.keys(validationObj.error).length === 0;

    setValidState(validationObj);
  };

  return section ? (

    <React.Suspense>
      <FormFields
        sections={section}
        fields={formFields}
        formData={formData || {}}
        validState={validState}
        onFormStateChange={handleStateChange}
        onUpdateValidation={handleOnValidateField}
        onImageValidation={handleImageValidation}
        onFileValidation={handleFileValidation}
        onFieldBlur={onFieldBlur}
        onFormFieldChange={onColumnChange}
        formSetting={formSetting}
        fieldValiadtionRules={validationRules}
        onActionChange={onActionChange}
        {...otherProps}  // Pass down other props to FormFields
      />
    </React.Suspense>
  ) : (
    <div>No fields configured</div>
  );

});

FormCreator.propTypes = {
  initialState: PropTypes.object,
  config: PropTypes.shape({
    section: PropTypes.array.isRequired,
    formFields: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        label: PropTypes.string,
        value: PropTypes.any,
      })
    ),
    formSetting: PropTypes.shape({
      isViewOnly: PropTypes.bool,
    }),
    initialState: PropTypes.object,
  }).isRequired,
  onFormDataChange: PropTypes.func,
  onColumnChange: PropTypes.func,
  onFieldBlur: PropTypes.func,
  onActionChange: PropTypes.func,
  onSubmit: PropTypes.func,
};


export default React.memo(FormCreator);