/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import "./UndefinedOrder.scss";
import formatDate from "../../../lib/formatDate";
import UndefinedOrderDetail from "./UndefinedOrderDetail";
import CardSection from "../../../components/ui/card/CardSection";
import CenterModel from "../../../components/ui/centerModel/CenterModel";
import SyncOrderDetails from "./features/syncorderdetail/SyncOrderDetails";
import { useGetUndefinedOrderEmailsMutation } from "../../../app/services/orderAPI";
import { FirstSecondLetter } from "../../../utils/FirstSecLetter/FirstSecondLetter";

//** Compoent's */
const AddOrderTabs = React.lazy(() => import("../AddOrderTabs/addOrderTabs"));

const UndefinedOrder = () => {

  const [, setTotalRowCount] = useState(0);
  const [emailList, setEmailList] = useState([]);
  const [showOrderEntryPopup, setshowOrderEntryPopup] = useState(false);
  const [showOrderSyncPopup, setShowOrderSyncPopup] = useState(false);

  //OrderEntryCenterModel
  const closeOrderEntryPopup = () => {
    setshowOrderEntryPopup(false);
  };
  const handleOpenOrderEntryPopup = () => {
    setshowOrderEntryPopup(true);
  };

  //OrderSyncCenterModel
  const closeOrderSyncPopup = () => {
    setShowOrderSyncPopup(false);
  };
  const handleOpenOrderSyncPopup = () => {
    setShowOrderSyncPopup(true);
  };

  const [activeTab, setActiveTab] = useState(null);
  const [
    getUndefinedOrderEmails,
    {
      isFetching: isGetUndefinedOrderEmailsFetching,
      isSuccess: isGetUndefinedOrderEmailsSuccess,
      data: isGetUndefinedOrderEmailsData,
    },
  ] = useGetUndefinedOrderEmailsMutation();

  useEffect(() => {
    getLists();
  }, []);

  const getLists = () => {
    const request = {
      pagination: {
        pageNumber: 1,
        pageSize: 25,
      },
      filters: { searchText: "" },
      sortString: "",
    };
    getUndefinedOrderEmails(request);
  };

  useEffect(() => {
    if (
      !isGetUndefinedOrderEmailsFetching &&
      isGetUndefinedOrderEmailsSuccess &&
      isGetUndefinedOrderEmailsData
    ) {
      if (isGetUndefinedOrderEmailsData) {
        setEmailList(isGetUndefinedOrderEmailsData.dataSource);
        setActiveTab(isGetUndefinedOrderEmailsData.dataSource[0]?.emailId);
      }
      if (isGetUndefinedOrderEmailsData.totalRecord) {
        setTotalRowCount(isGetUndefinedOrderEmailsData.totalRecord);
      }
    }
  }, [
    isGetUndefinedOrderEmailsFetching,
    isGetUndefinedOrderEmailsSuccess,
    isGetUndefinedOrderEmailsData,
  ]);
  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  return (
    <>
      <div className="row left-tab-section-list">
        <div className="col-3 pl-1 pr-1 h-100">
          <CardSection
            cardTitle="Undefined Emails"
            rightButton={true}
            buttonClassName="theme-button"
            buttonText="Upload PO"
            titleButtonClick={handleOpenOrderEntryPopup}
          >
            <div className="email-list">
              {emailList.map((item, index) => (
                <div
                  key={index}
                  className={`email-item ${activeTab === item.emailId ? "active" : ""}`}
                  onClick={() => handleTabClick(item.emailId)}
                >
                  <div className="d-flex align-items-center">
                    <span className="profile-icon">
                      {" "}
                      {FirstSecondLetter(item.subject)}
                    </span>
                    <div className="email-item-info">
                      {/* <span className="email-address">{item.emailId}</span> */}
                      <span className="email-subject">{item.subject}</span>
                    </div>
                  </div>
                  <div className="email-item-date mt-2">
                    <span>
                      {formatDate(item.emailDate, "MM/DD/YYYY hh:mm A")}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </CardSection>
        </div>

        <div className="col-xxl-9 col-xl-9 col-md-9 col-12 ">
          <div className="right-desc">
            <CardSection
              cardTitle="Email Details"
              rightButton={true}
              buttonClassName="theme-button"
              buttonText="Sync Order"
              titleButtonClick={handleOpenOrderSyncPopup}
            >
              <UndefinedOrderDetail emailId={activeTab} />
            </CardSection>
          </div>
        </div>
      </div>
      {showOrderEntryPopup && (
        <CenterModel showModal={showOrderEntryPopup} className="center-model po-model" headerClassName="po-model-title"
          handleToggleModal={closeOrderEntryPopup} modalTitle="Order Entry Details"
          modelSizeClass="width-98" >
          <AddOrderTabs closeOrderEntryPopup={closeOrderEntryPopup} />
        </CenterModel>
      )}
      {showOrderSyncPopup && (
        <CenterModel
          showModal={showOrderSyncPopup}
          className="center-model"
          handleToggleModal={closeOrderSyncPopup}
          modalTitle="Sync Order Details"
          modelSizeClass="width-70"
        >
          <SyncOrderDetails />
        </CenterModel>
      )}
    </>
  );
};
export default UndefinedOrder;
