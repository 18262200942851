import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types"; // Import PropTypes for prop validation
import "./DropdownWrapper.scss";

// Custom hook for managing the dropdown's state and position
const useDropdown = (direction = 'right') => {
  const [isOpen, setIsOpen] = useState(false); // Tracks whether the dropdown is open or closed
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 }); // Stores dropdown position
  const dropdownRef = useRef(null); // Ref for the dropdown content
  const toggleElementRef = useRef(null); // Ref for the element that triggers the dropdown

  // Function to toggle the dropdown's open/closed state
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  // Function to close the dropdown
  const closeDropdown = () => {
    setIsOpen(false);
  };

  // Handles clicks outside of the dropdown to close it
  const handleOutsideClick = (e) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target) &&
      toggleElementRef.current &&
      !toggleElementRef.current.contains(e.target)
    ) {
      closeDropdown();
    }
  };

  // Updates the dropdown position to avoid overflow on the screen
  const updateDropdownPosition = () => {
    if (toggleElementRef.current && dropdownRef.current) {
      const toggleRect = toggleElementRef.current.getBoundingClientRect(); // Get position of toggle element
      const dropdownRect = dropdownRef.current.getBoundingClientRect(); // Get position of dropdown
      const viewportWidth = window.innerWidth; // Get width of the viewport
      const viewportHeight = window.innerHeight; // Get height of the viewport

      let top = toggleRect.bottom;
      let left = toggleRect.left;

      // Check the direction and adjust accordingly
      if (direction === "right") {
        // Open to the right (start from the left edge of the toggle element)
        left = toggleRect.right; // Correct position to open on the right

        // Adjust position if dropdown overflows at the right
        if (toggleRect.right + dropdownRect.width > viewportWidth) {
          left = toggleRect.left - dropdownRect.width; // Switch to left if not enough space on the right
        }
      } else if (direction === "left") {
        // Open to the left (start from the left edge of the toggle element)
        left = toggleRect.left - dropdownRect.width; // Correct position to open on the left

        // Adjust position if dropdown overflows at the left
        if (left < 0) {
          left = toggleRect.right; // Switch to right if not enough space on the left
        }
      }

      // Adjust position if dropdown overflows at the bottom
      if (toggleRect.bottom + dropdownRect.height > viewportHeight) {
        top = toggleRect.top - dropdownRect.height;
      }

      // Prevent dropdown from overflowing on the top
      if (top < 0) {
        top = toggleRect.bottom;
      }

      setDropdownPosition({ top, left });
    }
  };

  // Event listener to close dropdown if clicked outside
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // Update dropdown position when it is opened or when scrolling occurs
  useEffect(() => {
    if (isOpen) {
      updateDropdownPosition();

      // Add scroll event listener to update position dynamically
      const handleScroll = () => {
        updateDropdownPosition();
      };

      window.addEventListener("scroll", handleScroll, true);

      return () => {
        window.removeEventListener("scroll", handleScroll, true);
      };
    }
  }, [isOpen]);

  // Update position on window resize
  useEffect(() => {
    const handleResize = () => {
      if (isOpen) {
        updateDropdownPosition();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpen]);

  // Close dropdown if the parent element goes out of view
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          closeDropdown(); // Close the dropdown if the parent is not visible
        }
      },
      { threshold: 0.1 } // Trigger when at least 10% of the element is visible
    );

    if (toggleElementRef.current) {
      observer.observe(toggleElementRef.current);
    }

    return () => {
      if (toggleElementRef.current) {
        observer.unobserve(toggleElementRef.current);
      }
    };
  }, []);

  // Return values and functions to control dropdown behavior
  return {
    isOpen,
    toggleDropdown,
    closeDropdown,
    dropdownRef,
    toggleElementRef,
    dropdownPosition,
  };
};

// DropdownWrapper component to render the dropdown toggle button and dropdown content
const DropdownWrapper = ({ children, toggleElement, className, direction = 'right' }) => {
  const {
    isOpen,
    toggleDropdown,
    dropdownRef,
    toggleElementRef,
    dropdownPosition,
    closeDropdown,
  } = useDropdown(direction); // Pass direction prop to the hook

  // Handle click on children to close the dropdown
  const handleChildClick = () => {
    closeDropdown(); // Close the dropdown when any child is clicked
  };

  return (
    <div className="dropdown-wrapper">
      <button
        ref={toggleElementRef}
        onClick={toggleDropdown}
        className="dropdownwrapper-toggle"
      >
        {toggleElement}
      </button>
      {isOpen && (
        <div
          ref={dropdownRef}
          className={`dropdownwrapper-content ${className}`}
          style={{
            position: "absolute",
            top: dropdownPosition.top,
            left: dropdownPosition.left,
          }}
        >
          <div onClick={handleChildClick}>{children}</div>
        </div>
      )}
    </div>
  );
};

// PropTypes validation for DropdownWrapper
DropdownWrapper.propTypes = {
  children: PropTypes.node.isRequired, // Dropdown content (can be any renderable React node)
  toggleElement: PropTypes.node.isRequired, // Element that triggers the dropdown
  direction: PropTypes.oneOf(["left", "right"]), // Direction the dropdown should open
};

DropdownWrapper.defaultProps = {
  direction: "right", // Default to opening to the right
};


// Export the hook and component
export { useDropdown, DropdownWrapper };