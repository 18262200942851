import { createSlice } from "@reduxjs/toolkit";
import { saveData } from "../../utils/LocalStorage/LocalStorageManager";
import { getAuthProps, setAuthProps, signOut } from "../../lib/authenticationLibrary";

const authData = getAuthProps();

const data = {
  "message": "File uploaded successfully",
  "file_name": "0a2bf2d8-b629-4af5-b6eb-34489161fe9b.pdf",
  "file_path": "/app/static/uploads/0a2bf2d8-b629-4af5-b6eb-34489161fe9b.pdf",
  "pdf_text": "Gilead Sciences Inc 333 Lakeside Drive Foster City CA United States 94404 Purchase Order Date 362023 PONumber 801645810 Revision No 0 Release No Supplier AURUM PHARMATECH LLC PO BOX 36 FRANKLIN PARK NJ 08823 United States Ship To Gilead Sciences Inc 199 E Blaine Street Seattle WA 98102 United States Currency USD This Purchase Order is subject to Gilead Standard Purchase Order Terms Conditions which can be found at wwwgileadcompdfnorthamericatermspdf and are incorporated into the face hereof Order Date 030623 Request Date 030723 Ship Via FOB Freight Terms DESTINATION Due Misc Information Line CatalogItem Description Qty UOM Unit Price Extended Amount Promise Date 11 GN48159 2Bromo5678tetrahydro124triazolo15 apyrazine 1 g Attn Lee Seung H tonyleegileadcom 1 Each 199100 199100 030723 Payment Terms Net 30 Tax Rate Sales TaxVAT Total Order Amount Buyer System Authorized Signature 199100 Please submit invoices through Transcepta using your preferred method of submission For any further information please contact Transcepta at supporttransceptacom Contact Gilead Gilead Sciences Inc PO Box 5469 San Mateo CA 94402 For Payment Inquiries send an email to APInquiryGileadcom You must quote a valid Gilead Purchase Order Number to ensure payment of your invoice",
  "result": {
    "SupplierName": "AURUM PHARMATECH LLC",
    "CreatedAt": "362023",
    "PONumber": "801645810",
    "SupplierAddress": "PO BOX 36 FRANKLIN PARK NJ 08823 United States",
    "ProductDetails": [
      {
        "CatalogID": "GN48159",
        "CASNumber": "Undefined",
        "Size": "1 g",
        "Qty": "1",
        "Price": "199100",
        "ProductName": "2Bromo5678tetrahydro124triazolo15 apyrazine"
      },
      {
        "CatalogID": "Undefined",
        "CASNumber": "Undefined",
        "Size": "Undefined",
        "Qty": "1",
        "Price": "Undefined",
        "ProductName": "Undefined"
      }
    ],
    "CompanyInformation": [
      {
        "CompanyName": "Gilead Sciences Inc",
        "CompanyAddress": "333 Lakeside Drive Foster City CA United States 94404"
      }
    ],
    "ShippingInformation": {
      "ShipTo": "199 E Blaine Street Seattle WA 98102 United States"
    },
    "SendInvoiceEmail": {
      "EmailInvoice": "supporttranscepta.com"
    },
    "PaymentInquiries": {
      "PaymentInquiriesEmail": "APInquiryGileadcom"
    },
    "PersonInfo": {
      "Name": "Lee Seung H",
      "EmailId": "tonyleegileadcom"
    }
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: (authData != null),
    user: (authData?.user), //Set user data from cookie.
    token: (authData?.token), //Set token data from cookie.
    roles: (authData?.roles),
    authData,
    isLogedin: false
  },
  reducers: {
    authentication: (state, action) => {
      //Use to create cookie.
      const { securityPermissions, approvalRulesConfiguration, smtpSettings, ...newAuthProps } = action.payload;
      setAuthProps(newAuthProps);
      const { isAuthenticated, message, token, user, sessionTimeout, fullname, roles, ...permissionList } = action.payload;
      state.user = user;
      state.roles = roles;
      state.userPermissions = permissionList;
      saveData('SecurityPermission', permissionList);
      saveData('approvalRules', approvalRulesConfiguration);
      saveData('smtpSettings', smtpSettings);
      saveData('POInformation', data);
      state.isLogedin = true;
      state.isPasswordResetRequired = action.payload.user.passwordResetRequired ? action.payload.user.passwordResetRequired : false
    },
    logout: (state) => {
      state.isLogedin = false;
      signOut();
    },
    updatePermission: (state, action) => {
      saveData('SecurityPermission', action.payload);
      state.userPermissions = action.payload;
    }
  }
});


export const { authentication, logout } = authSlice.actions;
export default authSlice.reducer;