import FileViewer from "react-file-viewer";

export const getDropdownLabelName = (apiResponseData, valueField, labelField, selectedId) => {
    if (apiResponseData && selectedId && valueField && labelField) {
        // Map the data to the required format
        const mappedData = apiResponseData.map((item) => ({
            value: item[valueField],
            label: item[labelField]
        }));

        // Find the matching entry based on selectedId
        const labelName = mappedData.find(data => Number(data.value) === Number(selectedId));
        if (labelName) {
            return labelName.label; // Return the label field value
        }
    }
    return null;
}


export const removeIdSuffix = (fieldName) => {
    if (fieldName.toLowerCase().endsWith('id')) {
        return fieldName.slice(0, -2); // Remove the last 2 characters ("Id")
    }
    return fieldName;
};

export const getValue = (data) => {
    return data && typeof data === "object" ? data.value : data;
};

export const extractEmail = (email) => {
    const match = email.match(/<(.+?)>/);
    return match ? match[1] : email; // Fallback to the original string if no match
};



export const renderDocumentViewContent = (selectedDocument, getFileType) => {
    if (selectedDocument && getFileType) {
        if (getFileType === "pdf") {
            return (
                <div className="pdf-iframe">
                    <iframe
                        src={selectedDocument}
                        title="PDF Preview"
                        style={{ width: "100%", height: "200%" }}
                    />
                </div>
            );
        } else {
            return (
                <FileViewer
                    fileType={getFileType}
                    filePath={selectedDocument}
                    onError={(error) => console.error("Error:", error)}
                />
            );
        }
    }
    return null;
};

export const getClassForBadges = (status) => {
    const classMap = {
        Incoterms: { color: "#80bc29" },
        pendingcustomer: { color: "#fe0808" },
        "Payment Terms Not Matching": { color: "#d84444" },
        ponumberiswrong: { color: "#003087" },
        creditlimitcrossed: { color: "#ff9a00" },
        billingaddressmismatch: { color: "#1e87ca" },
        invoiceemailmismatch: { color: "#9e027e" },
        pastdue: { color: "#683bb5" },
        replacement: { color: "#393939" },
        leadtime: { color: "#de481e" },
        stockverification: { color: "#ffc82b" },
        pricemismatch: { color: "#00abe6" },
    };

    return classMap[status] || { color: "#000000" }; // Fallback to black if status is not found
};