/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
//** Lib's */
import { keyCodes } from "ckeditor5";
import ToastService from "../../../../../services/toastService/ToastService";
import SwalAlert from "../../../../../services/swalService/SwalService";
import { ErrorMessage } from "../../../../../data/appMessages";
import CardSection from "../../../../../components/ui/card/CardSection";
import FinalMolGrid from "../../../../../components/FinalMolGrid/FinalMolGrid";
import {
  useGetPendingActionOrderMutation,
  useLazyGetPendingActionStatusByOrderIdQuery,
  useDeleteOrderMutation,
  useLazyGetOrderDetailByOrderIdQuery,
  useLazyGetOrderHistoryByOrderIdQuery,
} from "../../../../../app/services/orderAPI";
import {
  PendingActionCustomerList,
  PendingActionItemList,
} from "../config/PendingActionConfig";
import SidebarModel from "../../../../../components/ui/sidebarModel/SidebarModel";
import OrderComments from "../../../orderDetail/feature/orderaction/feature/OrderComments";
import { AppIcons } from "../../../../../data/appIcons";
//** Service's */
import { encryptUrlData } from "../../../../../services/CryptoService";
import { useLazyGetCustomerInformationByCustomerIdQuery } from "../../../../../app/services/basicdetailAPI";
import { OrderBadges } from "../../../../../utils/Enums/commonEnums";
import OrderHistory from "../../../orderDetail/feature/orderaction/feature/OrderHistory";
import KeyCodes from "../../../../../utils/Enums/KeyCodesEnums";

const PendingOrderList = ({ orderStatusId, isCustomer }) => {
  const molGridRef = useRef();
  const { confirm } = SwalAlert();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [
    deleteOrder,
    { isSuccess: isDeleteOrderSuccess, data: isDeleteOrderData },
  ] = useDeleteOrderMutation();
  const [
    getOrders,
    {
      isLoading: isGetPendingActionOrderLoading,
      isSuccess: isGetPendingActionOrderSuccess,
      data: isGetPendingActionOrderData,
    },
  ] = useGetPendingActionOrderMutation();
  const [
    getPendingActionStatusByOrderId,
    {
      isLoading: isGetPendingActionOrderStatusLoading,
      isFetching: isPendingListFeatching,
      isSuccess: isGetPendingActionStatusByOrderIdSuccess,
      data: isGetPendingActionStatusByOrderIdData,
    },
  ] = useLazyGetPendingActionStatusByOrderIdQuery();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isHardDelete, setIsHardDelete] = useState(false);
  const [tempDataSource, setTempDataSource] = useState([]);
  const [itemStatusDataSource, setItemStatusDataSource] = useState([]);
  const [
    getOrderDetailByOrderId,
    { isFetching: isOrderDetailsFeatching, data: isOrderByOrderIdDetails },
  ] = useLazyGetOrderDetailByOrderIdQuery();
  const [
    getCustomerInfo,
    {
      isFetching: isCustomerDetailsFeatching,
      isLoading: isGetCustomerInfoLoading,
      data: isGetCustomerInfoData,
    },
  ] = useLazyGetCustomerInformationByCustomerIdQuery();
  const [getOrderHistory,{isFetching: isGetOrderHistoryByOrderIdFetching,isSuccess: isGetOrderHistoryByOrderIdSuccess,data: isGetOrderHistoryByOrderIdData,}, ] = useLazyGetOrderHistoryByOrderIdQuery();
  const [historyList, setHistortyList] = useState([]);
  const[isTimeLineOpen,setIsTimeLineOpen]=useState(false);

  useEffect(() => {
    onGetData();
    setItemStatusDataSource([]);
  }, [orderStatusId, isCustomer]);
  const handleSearch = useCallback(() => {
    if (search.length >= 3) {
      onGetData();
    } else {
      ToastService.warning(ErrorMessage.CommonErrorMessage);
    }
  }, [search]);
  useEffect(() => {
    if (isDeleteOrderSuccess && isDeleteOrderData) {
      ToastService.success(isDeleteOrderData.errorMessage);
      onGetData();
    }
  }, [isDeleteOrderSuccess, isDeleteOrderData]);

  const handleChange = (event) => {
    setSearch(event.target.value.trim());
  };
  const handleKeyPress = (event) => {
    if (event.key === KeyCodes.ENTER) {
      handleSearch();
    }
  };
  const handleClear = () => {
    setSearch("");
  };
  useEffect(() => {
    if (search === "") {
      onGetData();
    }
  }, [search]);
  useEffect(() => {
    if (isGetPendingActionOrderSuccess && isGetPendingActionOrderData) {
      if (isGetPendingActionOrderData) {
        const modifyCustomerData = isGetPendingActionOrderData.dataSource.map(
          (data) => ({
            ...data,
            comment: data.comment ? data.comment : "-",
          })
        );
        setDataSource(modifyCustomerData);
      }
      if (isGetPendingActionOrderData.totalRecord) {
        setTotalRowCount(isGetPendingActionOrderData.totalRecord);
      }
    }
  }, [isGetPendingActionOrderSuccess, isGetPendingActionOrderData]);
  // useEffect(() => {
  //   if (isGetCustomerInfoSuccess && isGetCustomerInfoData) {
  //     // setCustomerInformation(isGetCustomerInfoData);
  //   }
  // }, [isGetCustomerInfoSuccess, isGetPendingActionStatusByOrderIdData]);

  useEffect(() => {
    if (
      !isPendingListFeatching &&
      isGetPendingActionStatusByOrderIdSuccess &&
      isGetPendingActionStatusByOrderIdData
    ) {
      const modifyCustomerData = isGetPendingActionStatusByOrderIdData.map(
        (data) => ({
          customerName: data.customerName === "" ? "-" : data.customerName,
          catalogId: data.catalogId === "" ? "-" : data.catalogId,
          chemicalName: data.chemicalName ? data.chemicalName : null,
          casNumber: data.casNumber === "" ? "-" : data.casNumber,
          itemUnitPrice:
            data.itemUnitPrice === "" ? "-" : `$${data.itemUnitPrice}`,
          status: data.status === "" ? "-" : data.status,
          quantity: data.quantity ? data.quantity : null,
          unit: data.unit ? data.unit : null,
          packSize: data.packSize ? data.packSize : null,
          orderSubStatusId: data?.orderSubStatusId,
        })
      );
      setTempDataSource(modifyCustomerData);
    }
  }, [
    isPendingListFeatching && isGetPendingActionStatusByOrderIdSuccess,
    isGetPendingActionStatusByOrderIdData,
  ]);

  useEffect(() => {
        if (!isGetOrderHistoryByOrderIdFetching && isGetOrderHistoryByOrderIdSuccess && isGetOrderHistoryByOrderIdData) {
          if (isGetOrderHistoryByOrderIdData) {
            setHistortyList(isGetOrderHistoryByOrderIdData);
          }
        }
      }, [isGetOrderHistoryByOrderIdFetching, isGetOrderHistoryByOrderIdSuccess, isGetOrderHistoryByOrderIdData]);

  const getLists = (pageObject, sortingString) => {
    const request = {
      pagination: {
        pageNumber: pageObject.pageNumber,
        pageSize: pageObject.pageSize,
      },
      filters: { searchText: search },
      sortString: sortingString,
      orderStatusId: orderStatusId,
      isCustomer: isCustomer,
    };
    getOrders(request);
  };
  const handlePageChange = (page) => {
    getLists(page, molGridRef.current.generateSortingString());
  };
  const handleSorting = (shortString) => {
    getLists(molGridRef.current.getCurrentPageObject(), shortString);
  };
  const onGetData = () => {
    if (molGridRef.current) {
      const defaultPageObject = molGridRef.current.getCurrentPageObject();
      getLists(defaultPageObject, molGridRef.current.generateSortingString());
    }
  };
  const handleEditClick = (data) => {
    const orderId = data.orderId;
    navigate(`/OrderDetails/${encryptUrlData(orderId)}`);
  };
  const handleDeleteClick = (data) => {
    confirm(
      "Delete?",
      "Are you sure you want to Delete?",
      "Delete",
      "Cancel"
    ).then((confirmed) => {
      if (confirmed) {
        const request = {
          orderId: data.orderId,
          isHardDelete: isHardDelete,
        };
        deleteOrder(request);
      }
    });
  };
  const handleRowClick = (data) => {
    setItemStatusDataSource([]);
    var request = {
      orderId: data.orderId,
      isCustomer: isCustomer,
    };
    // setRowData(data);
    getCustomerInfo(data.customerId);
    getOrderDetailByOrderId(data.orderId);
    getPendingActionStatusByOrderId(request);
  };
  const onSidebarClose = () => {
    setIsModelOpen(false);
    onGetData();
  };
  const handleAddComments = (data) => {
    setSelectedItem(data.orderId);
    setIsModelOpen(true);
  };
  const handleShowTimeLine = (data) => {
    setIsTimeLineOpen(true);
    getOrderHistory(data.orderId);
  };

  const handleToggleHistoryModal = () => {
    setIsTimeLineOpen(!isTimeLineOpen);
  };
  
  const actionHandler = {
    EDIT: handleEditClick,
    TOGGLEROW: handleRowClick,
    DELETE: handleDeleteClick,
    ADDCOMMENTS: handleAddComments,
    SHOWTIMELINE : handleShowTimeLine
  };

  useEffect(() => {
    if (
      tempDataSource.length > 0 &&
      isGetCustomerInfoData &&
      isOrderByOrderIdDetails &&
      !isOrderDetailsFeatching &&
      !isCustomerDetailsFeatching
    ) {
      const modifyCustomerData = tempDataSource.map((data) => {
        const modifiedData = {
          customerName: data.customerName === "" ? "-" : data.customerName,
          catalogId: data.catalogId === "" ? "-" : data.catalogId,
          chemicalName: data.chemicalName ? data.chemicalName : null,
          casNumber: data.casNumber === "" ? "-" : data.casNumber,
          itemUnitPrice:
            data.itemUnitPrice === "" ? "-" : `$${data.itemUnitPrice}`,
          status: data.status === "" ? "-" : data.status,
          quantity: data.quantity ? data.quantity : null,
          unit: data.unit ? data.unit : null,
          packSize: data.packSize ? data.packSize : null,
        };

        const billingAddress =
          isGetCustomerInfoData?.orderAddressInformation?.filter(
            (address) => address.addressTypeId === 1
          );

        if (data.orderSubStatusId === OrderBadges.PAYMENTTERMSNOTMATCHING) {
          modifiedData.customerDetails =
            isGetCustomerInfoData.paymentTermName || "-";
          modifiedData.orderDetails =
            isOrderByOrderIdDetails.paymentTerm || "-";
        } else if (data.orderSubStatusId === OrderBadges.INCOTERMS) {
          modifiedData.customerDetails =
            isGetCustomerInfoData.incotermName || "-";
          modifiedData.orderDetails =
            isOrderByOrderIdDetails.incotermName || "-";
        } else if (
          data.orderSubStatusId === OrderBadges.BILLINGADDRESSMISMATCH
        ) {
          if (billingAddress.length > 0) {
            modifiedData.customerDetails =
              billingAddress[0].addressLine1 +
              " , " +
              billingAddress[0].addressLine2 +
              " , " +
              billingAddress[0].cityName +
              " , " +
              billingAddress[0].stateCode +
              "  " +
              billingAddress[0].zipCode +
              " , " +
              billingAddress[0].countryName;
            modifiedData.orderDetails = "-";
          } else {
            modifiedData.customerDetails = "-";
          }
        } else if (data.orderSubStatusId === OrderBadges.INVOICEEMAILMISMATCH) {
          modifiedData.customerDetails =
            isGetCustomerInfoData?.emailAddress || "-";
          modifiedData.orderDetails = "-";
        } else {
          modifiedData.customerDetails = "-";
          modifiedData.orderDetails = "-";
        }
        return modifiedData;
      });
      setItemStatusDataSource(modifyCustomerData);
    }
  }, [
    tempDataSource,
    isGetCustomerInfoData,
    isOrderByOrderIdDetails,
    isOrderDetailsFeatching,
    isCustomerDetailsFeatching,
  ]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="order-listing">
          <CardSection
            cardTitle=""
            searchInput={true}
            handleChange={handleChange}
            searchInputName={"Search By CustomerName & PoNumber"}
            isCardSection={true}
            searchButton={true}
            searchbuttonText="Search"
            buttonClassName="theme-button"
            searchTitleButtonClick={handleSearch}
            clearButton={true}
            clearTitleButtonClick={handleClear}
            clearButtonText="Clear"
            clearButtonClassName="dark-btn"
            searchIconImg={AppIcons.SearchIcone}
            searchTextWithIcon={true}
            clearTextWithIcon={true}
            searchValue={search}
            clearIconImg={AppIcons.ClearIcone}
            handleKeyPress={handleKeyPress}
          >
            <div className="sub-grid-list-customer">
              <FinalMolGrid
                ref={molGridRef}
                configuration={
                  isCustomer ? PendingActionCustomerList : PendingActionItemList
                }
                childTableDataSource={itemStatusDataSource}
                childTableLoading={
                  isGetPendingActionOrderStatusLoading ||
                  isGetCustomerInfoLoading
                }
                dataSource={dataSource}
                onPageChange={handlePageChange}
                isLoading={isGetPendingActionOrderLoading}
                allowPagination={true}
                onSorting={handleSorting}
                pagination={{
                  totalCount: totalRowCount,
                  pageSize: 20,
                  currentPage: 1,
                }}
                onActionChange={actionHandler}
              />
            </div>
          </CardSection>
          <SidebarModel
            modalTitle="Comments"
            contentClass="content-35"
            onClose={onSidebarClose}
            className="comments-sidebar"
            isOpen={isModelOpen}
            defaultPosition="left"
          >
            <OrderComments orderId={selectedItem} onClose={onSidebarClose} />
          </SidebarModel>
          
          <SidebarModel
            modalTitle="History"
            contentClass="content-50"
            onClose={handleToggleHistoryModal}
            className=""
            isOpen={isTimeLineOpen}
            defaultPosition="left"> 
              <OrderHistory historyList={historyList}/>
          </SidebarModel>   
        </div>
      </div>
    </div>
  );
};
PendingOrderList.propTypes = {
  orderSubStatusId: PropTypes.string.isRequired,
  isCustomer: PropTypes.number.isRequired,
};

export default PendingOrderList;
