/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState , useImperativeHandle} from "react";
import ToastService from "../../../../services/toastService/ToastService";
import SwalAlert from "../../../../services/swalService/SwalService";
import FinalMolGrid from "../../../../components/FinalMolGrid/FinalMolGrid";
import { SystemConstraintsGridConfig } from "../config/SystemConstraints.Data";
import { useDeleteSystemConstraintsByIdMutation, useGetSystemConstraintsMutation } from "../../../../app/services/systemConstraintsAPI";
import PropTypes from "prop-types";

const SystemConstraintsList = ({ handleEditClick, getDataRef ,handleSearch,handleChange, search,handleClear }) => {
  const molGridRef = useRef();
  const [listData, setListData] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const { confirm } = SwalAlert();
  const [deleteSystemConstraints, { isSuccess: isDeleteSystemConstraintsByIdSuccess, data: isDeleteSystemConstraintsByIdData },] = useDeleteSystemConstraintsByIdMutation();
  const [getSystemConstraints, { isLoading: isGetSystemConstraintsLoading, isSuccess: isGetSystemConstraintsSuccess, data: isGetSystemConstraintsData },] = useGetSystemConstraintsMutation();

  useEffect(() => {
    onGetData()
  }, []);

  useEffect(() => {
    if (search === "" ) {
      onGetData();
    }
    }, [search]);

  useEffect(() => {
    if (isGetSystemConstraintsSuccess && isGetSystemConstraintsData) {
      if (isGetSystemConstraintsData) {
        setListData(isGetSystemConstraintsData.dataSource);
      }
      if (isGetSystemConstraintsData.totalRecord) {
        setTotalRowCount(isGetSystemConstraintsData.totalRecord);
      }
    }
  }, [isGetSystemConstraintsSuccess, isGetSystemConstraintsData]);

  useEffect(() => {
    if (isDeleteSystemConstraintsByIdSuccess && isDeleteSystemConstraintsByIdData) {
      ToastService.success(isDeleteSystemConstraintsByIdData.errorMessage);
      const currentPageObject = molGridRef.current.getCurrentPageObject();
      handlePageChange(currentPageObject)
    }
  }, [isDeleteSystemConstraintsByIdSuccess, isDeleteSystemConstraintsByIdData]);


  const getLists = (pageObject, sortingString) => {
    const request = {
      pagination: {
        pageNumber: pageObject.pageNumber,
        pageSize: pageObject.pageSize,
      },
      filters: { searchText: search },
      sortString: sortingString,
    };
    getSystemConstraints(request);
  };

  const handlePageChange = (page) => {
    getLists(page, molGridRef.current.generateSortingString());
  };

  const handleSorting = (shortString) => {
    getLists(molGridRef.current.getCurrentPageObject(), shortString);
  }
  const onGetData = () => {
    if (molGridRef.current) {
      const defaultPageObject = molGridRef.current.getCurrentPageObject();
      getLists(defaultPageObject, molGridRef.current.generateSortingString());
    }
  }

  const handleDeleteClick = (data) => {
    confirm("Delete?", "Are you sure you want to Delete?", "Delete", "Cancel")
      .then((confirmed) => {
        if (confirmed) {
          deleteSystemConstraints(data.systemConstraintsId);
        }
      });
  };

  const actionHandler = {
    EDIT: handleEditClick,
    DELETE: handleDeleteClick,
  };


  useImperativeHandle(getDataRef, () => ({
    callChildFunction: onGetData
  }));

  return (

    <div className="row">
      <div className="col-md-12 table-striped dictionary-grid-list">
        <FinalMolGrid
          ref={molGridRef}
          configuration={SystemConstraintsGridConfig}
          dataSource={listData}
          allowPagination={true}
          pagination={{
            totalCount: totalRowCount,
            pageSize: 25,
            currentPage: 1,
          }}
          onPageChange={handlePageChange}
          onSorting={handleSorting}
          isLoading={isGetSystemConstraintsLoading}
          onActionChange={actionHandler}
          searchTitleButtonClick={handleSearch}
          handleChange={handleChange}
          handleClear={handleClear}
        />
      </div>
    </div>
  )
}


SystemConstraintsList.propTypes = {
  handleEditClick: PropTypes.func,
  getDataRef: PropTypes.object,
  handleSearch: PropTypes.func,
  handleChange: PropTypes.func,
  search: PropTypes.string,
  handleClear: PropTypes.func,
};

export default SystemConstraintsList;