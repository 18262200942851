import { EmailInboxTypesEnum } from "../../utils/Enums/commonEnums";
import EmailManagement from "./features/EmailManagement";
import PropTypes from "prop-types";

const DocumentRequestEmails=()=>{
    const documentRequest = [
        EmailInboxTypesEnum.COA,
        EmailInboxTypesEnum.MSDS,
        EmailInboxTypesEnum.TSABSESTMT,

      ].join(",");
    return(  <EmailManagement  emailIntentId={documentRequest} intentName="Document Request Emails (COA,MSDS,TSA BSE Stmt)"/>)
}
EmailManagement.propTypes = {
  emailIntentId: PropTypes.string.isRequired, 
  intentName: PropTypes.string.isRequired,
};

export default DocumentRequestEmails;