export const FieldSettingType = {
    DISABLED: "isDisable",
    ISTEXT: "isText",
    MULTISELECT: "isMultiSelect",
    ISPRIMARYBUTTONVISIBLE: "isPrimaryButtonVisible",
    ISINFOBUTTONVISIBLE: "isInfoButtonVisible",
    CKEDITORDISABLED: "CKEditorDisabled",
    ISMULTIPLE: "isMultiple",
    ISFILENAMECLEARED: "isFileNameCleared",
    ISTEMPLATEBTNDISABLE: "isTemplateBtnDisable"
}

export const CustomerSupplierStatus = {
    PENDING: 1,
    SUBMITTED: 2,
    APPROVED: 3,
    FREEZE: 4,
    BLOCK: 5,
    DISABLE: 6,
    REJECT: 7
}

export const ApprovalEnum = {
    APPROVECUSTOMER: 1,
    APPROVESUPPLIER: 2,
    APPROVESUBCUSTOMER: 3,
}

export const CustomerSupplierTabEnum = {
    BasicInformation: 1,
    Address: 2,
    Contact: 3,
    Documents: 4,
    FinancialSetting: 5,
    ShippingSetting: 6,
}

export const CustomerSettingEnum = {
    FinancialSettings: 0,
    ShippingSettings: 1
}

export const OwnerType = {
    Customer: 1,
    Supplier: 2
}


export const ListShowCustomer = { value: 1, label: "Customer Name" }
export const ListSupplier = { value: 2, label: "Supplier Name" }

export const AuthenticationTypes = {
    APIKey: "APIKey",
    OAuth: "OAuth"
}

export const ApiEndPointMethods = {
    GET: "GET",
    POST: "POST",
}

export const ApiParametersDataTypes = {
    String: "String",
    Number: "Number",
    Boolean: "Boolean",
}

export const PaymentMethodTypes = {
    CHECK: 1,
    ACHECHECK: 2,
    WIRE: 3,
    CREDITCARD: 4,
    ADVANCEDCOLLECT: 5,
    OTHERWITHNOTEDIELD: 6
}

export const Countrys = {
    USA: { countryId: 233, countryName: "United States" }
}

export const ModulePathName = {
    CUSTOMER: "Customer",
    SUPPLIER: "Supplier"
}

export const MaskingType = {
    CURRENCY: "currency",
    PERCENT: "percent"
}

export const SupplierFinancialSettings = {
    ACHWIRE: 1,
    CREDITCARD: 2,
    CHECK: 3,
    OTHER: 4,
}

export const OrderTabEnum = {
    OrderUploadPo: 0,
    OrderBasicInfo: 1,
    OrderFinanceAndContact: 2,
    OrderItems: 3,
}

export const MyTaskStatus = {
    Pending: "Pending",
    Accept: "Accept",
    Reject: "Reject"
}

export const ParameterType = {
    EVENT: "Event",
    PROVIDER: "Provider"
}

export const AddressType = {
    BILLING: 1,
    SHIPPING: 2,
    AP: 3,
    PRIMARY: 4,
    PHYSICALADDRESSHQ: 5,
    REMITTANCEADDRESS: 6,
    BANKADDRESS: 7
}

export const ContactType = {
    PRIMARY: 1,
    ENDUSER: 2,
    PURCHASING: 3,
    INVOICESUBMISSION: 4,
    INVOICEFOLLOWUP: 5,
    AP: 6,
    ACCOUNTSRECEIVABLE: 7,
    PURCHASEORDER: 8,
    SALESDEPARTMENT: 9,
    QCDEPARTMENT: 10
}

export const OrderInformationField = {
    CustomerName: "CustomerName",
    SubCustomer: "SubCustomer",
    Verify: "Verify"
}


export const Docum = {
    CustomerName: "CustomerName",
    SubCustomer: "SubCustomer",
    Verify: "Verify"
}

export const DocumentTypes = {
    TAXORREGISTRATIONDOCUMENT: 1,
    CUSTOMERDETAILSFORM: 2,
    OURSUBMITTEDFORMS: 3,
    SUPPLIERDETAILSFORM: 4
};
export const NoteTypes = {
    DefaultNote: "Default Note",
    InvoiceSubmissionInstruction: "Invoice Submission Instruction",

}

export const MyTaskFieldNames = {
    PAYMENTMETHODID: "PaymentMethodId",
    PAYMENTTERMID: "PaymentTermId"
}

export const MyTaskKeyNames = {
    PAYMENTMETHODID: "PaymentMethod",
    PAYMENTTERMID: "PaymentTerm"
}

export const EmailProviders = [
    { value: "Gmail", label: "Gmail" },
    { value: "Office365", label: "Office 365" },
    { value: "Outlook", label: "Outlook" },
]

export const DOCUMENTFORMATALLOW = [".pdf", ".docx"]

export const CustomerSupplierCustomeAction = {
    allowDisable: "ALLOWDISABLE",
    allowFreeze: "ALLOWFREEZE",
    allowBlocked: "ALLOWBLOCKED",
    allowReject: "ALLOREJECT",
    allowUnBlocked: "ALLOWUNBLOCKED",
    allowUnFreeze: "ALLOWUNFREEZE",
    allowLoginHistory: "HISTORY",
}
export const ModuleBucketType = {
    CUSTOMERDOCUMENT: "65c78959",
    SUPPLIERDOCUMENT: "ac117d14",
    ORDERDOCUMENT: "133557c7",
    EMAILATTACHMENT: "12d551b2"
};


export const EmailInboxTypesEnum = {
    QUOTATION: 1,
    ORDERFOLLOWUP: 2,
    PURCHASEORDER: 3,
    COA: 4,
    MSDS: 5,
    TSABSESTMT: 6,
    QUALITYISSUE: 9,
    ACCOUNTINGRELATED: 10,
    NEWCOMPANYSETUP: 11,
    OTHERS: 12,

};

export const UploadPOTabs = {
    UPLOADPO: 1,
    POBASICINFO: 2,
    POCONTACTS: 3
}

export const SourceTypes = {
    WebSite: "WebSite",
    Email: "Email",
}

export const DocumentTypeId = {
    ORDERPODOCUMENT: 23 // This is master table type id
}

export const OrderBadges = {
    PENDINGCUSTOMER: 1,
    PAYMENTTERMSNOTMATCHING: 2,
    PONUMBERISWRONG: 3,
    CREDITLIMITCROSSED: 4,
    BILLINGADDRESSMISMATCH: 5,
    INVOICEEMAILMISMATCH: 6,
    INCOTERMS: 7,
    PASTDUE: 8,
    REPLACEMENT: 9,
    LEADTIME: 10,
    STOCKVERIFICATION: 11,
    PRICEMISMATCH: 12,
};

export const ChargesTypes={ 
    ShippingCharges:"Shipping Charges",
    HandlingCharges:"Handling Charges",    
    CardProcessingCharges:"Card Processing Charges",
    OtherCharges:"Other Charges",

}