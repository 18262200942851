import { useState,useRef } from "react";
import CardSection from "../../../components/ui/card/CardSection";
import SidebarModel from "../../../components/ui/sidebarModel/SidebarModel";
import { AppIcons } from "../../../data/appIcons";
import { onResetForm } from "../../../utils/FormFields/ResetForm/handleResetForm";
import { ErrorMessage } from "../../../data/appMessages";
import ToastService from "../../../services/toastService/ToastService";
import KeyCodes from "../../../utils/Enums/KeyCodesEnums";
import { useCallback } from "react";
import SystemConstraintsList from "./features/SystemConstraintsList";
import AddEditSystemConstraints from "./features/AddEditSystemConstraints";
import { SystemConstraintsFormData } from "./config/SystemConstraints.Data";
 
const SystemConstraints = () => {
  const getDataRef=useRef();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState(SystemConstraintsFormData);
  const [search, setSearch] = useState("");

  const handleToggleModal = () => {
    onResetForm(SystemConstraintsFormData,setFormData, null);
    setIsModelOpen(true);
 
  };

  const onSidebarClose = () => {
    setIsModelOpen(false);
    setIsEdit(false);
  };

  const handleEditClick = (data) => {
    onResetForm(SystemConstraintsFormData,setFormData, null);
    setIsModelOpen(true);
    setFormData(data);
    setIsEdit(true);  
  };

  const onSuccess = () => {
    if (getDataRef.current) {
      getDataRef.current.callChildFunction();
    }
  };

  const handleChange = (event) => {
    setSearch(event.target.value.trim());
  };

  const handleSearch = useCallback(() => {
    if (search.length >= 3 ) {
      onSuccess();
    } else {
      ToastService.warning(ErrorMessage.CommonErrorMessage);
    }
  }, [search]);

  const handleKeyPress=(event)=>{
    if (event.key === KeyCodes.ENTER) {
      handleSearch();
    }
  }

  const handleClear = () => {
    setSearch("");   
  };

  return (
    <div>
      <CardSection
        cardTitle="System Constraints"
        buttonClassName="btn theme-button"
        rightButton={true}
        textWithIcon={true}
        iconImg={AppIcons.PlusIcon}
        titleButtonClick={handleToggleModal}
        handleChange={handleChange}
        searchInputName="Search By Key"
        searchInput={true}
        searchButton={true}
        searchbuttonText="Search"
        searchTitleButtonClick={handleSearch}
        clearButton={true}
        clearTitleButtonClick={handleClear}
        clearButtonText="Clear"
        clearButtonClassName="dark-btn"
        searchIconImg={AppIcons.SearchIcone}
        searchTextWithIcon={true}
        clearTextWithIcon={true}
        clearIconImg={AppIcons.ClearIcone}
        searchValue={search}
        handleKeyPress={handleKeyPress}
      >
        <SystemConstraintsList
          getDataRef={getDataRef}
          handleEditClick={handleEditClick}
          initData={formData}
          handleSearch={handleSearch}
          handleChange={handleChange}
          search={search}
          handleClear={handleClear}
        />
      </CardSection>

      <SidebarModel
        modalTitle={
          isEdit ? "Update System Constraints " : "Add System Constraints"
        }
        contentClass="content-40"
        onClose={onSidebarClose}
        modalTitleIcon={AppIcons.AddIcon}
        isOpen={isModelOpen}
      >
        <AddEditSystemConstraints
          isEdit={isEdit}
          initData={formData}
          onClose={onSidebarClose}
          onSuccess={onSuccess}
          isModelOpen={isModelOpen}     
        /> 
      </SidebarModel>
    </div>
  );
};

export default SystemConstraints;
