import PropTypes from 'prop-types';
import { validationTypes } from '../../../components/FinalForms/libs/data/ValidationTypes';

/**
 * Checks if a specific field exists within the form fields data by its field ID.
 * @param {Object} formFieldsData - The object containing all form fields data.
 * @param {string} fieldId - The identifier of the field to check.
 * @returns {boolean} - True if the field exists, otherwise false.
 */
export const isFieldPresent = (formFieldsData, fieldId) => {
    if (formFieldsData?.section) {
        for (const section of formFieldsData.section) {
            let field = section.fields.some((item) => item.dataField === fieldId);
            if (field) return true;

            if (section.rowGroup) {
                for (const row of section.rowGroup) {
                    field = row.fields.some((field) => field.id === fieldId);
                    if (field) return true;
                }
            }
        }
    }
    return false;
};

/**
 * Finds a specific field data object within the form fields data by its field ID.
 * @param {Object} formFieldsData - The object containing all form fields data.
 * @param {string} fieldId - The identifier of the field to find.
 * @returns {Object|null} - The field data object if found, otherwise null.
 */
export const getFieldData = (formFieldsData, fieldId) => {
    if (formFieldsData?.section) {
        for (const section of formFieldsData?.section) {
            let field = section.fields.find((item) => item.dataField === fieldId);
            if (field) return field;

            if (section.rowGroup) {
                for (const row of section.rowGroup) {
                    field = row.fields.find((field) => field.id === fieldId);
                    if (field) return field;
                }
            }
        }
    }
    return null;
};
// Define propTypes for the function parameters
getFieldData.propTypes = {
    formFieldsData: PropTypes.array.isRequired,
    fieldId: PropTypes.string.isRequired
};


/**
 * Sets the options for a dropdown field within form fields data based on API response data.
 * 
 * @param {Array} apiResponseData - The data received from an API response.
 * @param {string} valueField - The field name to be used for option values.
 * @param {string} labelField - The field name to be used for option labels.
 * @param {object} formFieldsData - The object containing form fields data.
 * @param {string} fieldId - The identifier for the dropdown field to be updated.
 * @param {function} [filterCondition=null] - An optional filter condition function to filter the API response data.
 * 
 * Example usage:
 * const filterCondition = (item) => {
        return (item) => item.isActive;
    };
 * setDropDownOptionField(apiResponseData, 'id', 'name', formFieldsData, 'countryDropdown', filterCondition);
 */
export const setDropDownOptionField = (apiResponseData, valueField, labelField, formFieldsData, fieldId, filterCondition = null, setFormData, extraKeys = []) => {

    // Filter the API response data if a filter condition is provided
    const filteredData = filterCondition ? apiResponseData?.filter(filterCondition) : apiResponseData;

    // Map the filtered data to the required format
    const mappedData = filteredData?.map((item) => {
        const options = {
            value: item[valueField],
            label: item[labelField],
        }

        // Dynamically add additional properties from extraKeys
        extraKeys?.forEach(key => {
            if (key in item) {
                options[key] = item[key];
            }
        });

        return options;
    });

    // Find the dropdown field within the form fields data
    const dropdownField = getFieldData(formFieldsData, fieldId);

    // If the dropdown field is found, set its options to the mapped data
    if (dropdownField) {
        dropdownField.fieldSetting.options = mappedData;
    }
    setFormData && setFormData({ ...formFieldsData });
};

// Define propTypes for the function parameters
setDropDownOptionField.propTypes = {
    apiResponseData: PropTypes.array.isRequired,
    valueField: PropTypes.string.isRequired,
    labelField: PropTypes.string.isRequired,
    formFieldsData: PropTypes.array.isRequired,
    fieldId: PropTypes.string.isRequired,
    filterCondition: PropTypes.func
};


/**
 * Sets a specific field setting for a given field within form fields data.
 * 
 * @param {object} formFieldsData - The object containing form fields data.
 * @param {string} fieldId - The identifier for the field to be updated.
 * @param {string} fieldSettingType - The type of setting to be updated (e.g., 'isMultiSelect', 'isDisabled') and Also, create Enums for the setting type.
 * @param {any} [value=false] - The value to set for the specified setting type. Default is `false`.
 * 
 * Example usage:
 * setFieldSetting(formFieldsData, 'countryDropdown', FieldSettingType.DISABLED/FieldSettingType.MULTISELECT, true);
 */
export const setFieldSetting = (formFieldsData, fieldId, fieldSettingType, value = false) => {
    const selectField = getFieldData(formFieldsData, fieldId);
    if (selectField && selectField.fieldSetting) {
        switch (fieldSettingType) {
            case 'isMultiSelect':
                selectField.fieldSetting.isMultiSelect = value;
                break;
            case 'isDisable':
                selectField.fieldSetting.isDisable = value;
                break;
            case 'isPrimaryButtonVisible':
                selectField.inputButtonGroupConfig.isPrimaryButtonVisible = value;
                break;
            case 'isInfoButtonVisible':
                selectField.fieldSetting.isFormControl = value;
                break;
            case 'isText':
                selectField.fieldSetting.isText = value;
                break;
            case 'CKEditorDisabled':
                selectField.fieldSetting.isDisable = value;
                break;
            case 'isMultiple':
                selectField.fieldSetting.isMultiple = value;
                break;
            case 'isFileNameCleared':
                selectField.fieldSetting.isFileNameCleared = value;
                break;
            case 'isTemplateBtnDisable':
                selectField.fieldSetting.isTemplateBtnDisable = value;
                break;
            default:
                break;
        }
    }
};
// Define propTypes for the function parameters
setFieldSetting.propTypes = {
    formFieldsData: PropTypes.array.isRequired,
    fieldId: PropTypes.string.isRequired,
    fieldSettingType: PropTypes.array.isRequired,
    value: PropTypes.bool
};

/**
 * Add or remove validation from specific form fields in the form data.
 * 
 * @param {object} formData - The current form data object.
 * @param {Array} fieldsToUpdate - An array of field dataField IDs to update validation for.
 * @param {boolean} isRemove - Determines whether to remove the validation (true) or add required validation (false).
 * @returns {object} - The modified form data with updated validation.
 */
export const updateFieldValidationInForm = (formData, fieldToUpdate, isRemove = false) => {
    // Create a copy of the formData object
    const modifiedData = { ...formData };

    // Normalize fieldToUpdate to lowercase for case-insensitive comparison
    const lowerCaseFieldsToUpdate = Array.isArray(fieldToUpdate) ?
        fieldToUpdate.map((field) => field?.toLowerCase()) :
        [fieldToUpdate?.toLowerCase()];

    // Helper function to update validation
    const updateValidation = (field) => {
        if (isRemove) {
            const { validation, ...rest } = field;
            return rest;  // Remove validation
        } else {
            return { ...field, validation: [{ type: validationTypes.REQUIRE }] };  // Add required validation
        }
    };

    // If there are sections, iterate over each to update validation in fields and nested fields
    if (modifiedData.section) {
        modifiedData.section = modifiedData.section.map((section) => {
            const updatedSection = { ...section };

            // Update validation for fields at the section level
            updatedSection.fields = updatedSection.fields.map((field) =>
                lowerCaseFieldsToUpdate.includes(field.dataField?.toLowerCase())
                    ? updateValidation(field)
                    : field
            );

            // If there are rowGroups, update validation for fields within each row
            if (updatedSection.rowGroup) {
                updatedSection.rowGroup = updatedSection.rowGroup.map((row) => {
                    const updatedRow = { ...row };
                    updatedRow.fields = updatedRow.fields.map((field) =>
                        lowerCaseFieldsToUpdate.includes(field.dataField?.toLowerCase())
                            ? updateValidation(field)
                            : field
                    );
                    return updatedRow;
                });
            }

            return updatedSection;
        });
    }

    // Return the modified form data
    return modifiedData;
};

// Define propTypes for the function parameters
updateFieldValidationInForm.propTypes = {
    formData: PropTypes.object.isRequired,         // Form data object containing the sections and fields
    fieldsToUpdate: PropTypes.array.isRequired,    // Array of field IDs whose validation needs to be updated
    isRemove: PropTypes.bool.isRequired            // Boolean flag to determine if validation should be removed or added
};
