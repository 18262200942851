import React from "react";
import "./Comments.scss";
import NoRecordFound from "../../../../components/ui/noRecordFound/NoRecordFound";
import PropTypes from "prop-types";
import { getAuthProps } from "../../../../lib/authenticationLibrary";
import KeyCodes from "../../../../utils/Enums/KeyCodesEnums";
import { getInitials } from "../../../../utils/Comments.js/Comments";
import DataLoader from "../../../../components/FinalMolGrid/ui/dataLoader/DataLoader";
import formatDate from "../../../../lib/formatDate";

const Comments = ({ comments, onhandleSubmit, setNewComment, newComment, isCommetsOrderFetching }) => {
  const authData = getAuthProps();
  const userName = authData.user.fullName;

  const handleInputChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === KeyCodes.ENTER) {
      onhandleSubmit();
    }
  };

  return (
    <div className="comments">
      <div className="comment-box">
        {isCommetsOrderFetching ? <DataLoader /> :
          Array.isArray(comments) && comments.length > 0 ? (
            comments.map((postedComment, index) => (
              <div key={index} className="comment-section">
                <div className="comment-avatar">
                  {/* Display initials */}
                  <div className="avatar-circle">
                    {getInitials(postedComment.fullName)}
                  </div>
                </div>
                <div className="comment-container">
                  <div className="comment-description">
                    {postedComment.comment}
                    <div className="top-section">
                      <div className="comment-title">
                        <b>{postedComment.fullName}</b>
                      </div>
                      <div className="comment-date">
                        {formatDate(postedComment.createdAt)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>
              <NoRecordFound message="No Comments Found" />
            </div>
          )}
      </div>
      <div className="comment-post">
        <div className="comment-avatar">
          <div className="avatar-circle">{getInitials(userName)}</div>
        </div>
        <input
          type="text"
          placeholder="Please enter Comments..."
          value={newComment}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          className="input-field"
        />
        <button className="btn theme-button" onClick={onhandleSubmit}>
          Post
        </button>
      </div>
    </div>
  );
};

Comments.propTypes = {
  mongoDbEmailId: PropTypes.number,
  orderId: PropTypes.number,
  isEmail: PropTypes.bool,
  isOrder: PropTypes.bool,
};

export default Comments;
