import { useRef, useState, createContext } from 'react';
import PropTypes from 'prop-types';

const AddOrderContext = createContext();

export default AddOrderContext;

export const AddOrderContextProvider = ({ children }) => {

    const itemRef = useRef(null);
    const conatctRef = useRef(null);
    const basicInfoRef = useRef(null);
    const uploadPOStepRef = useRef(null);

    //** Reset Ref */
    // const poUploadRef = useRef(null);
    const conatctResetRef = useRef(null);
    const basicInfoResetRef = useRef(null);
    const uploadPOStepResetRef = useRef(null);

    const [orderId, setOrderId] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [productId, setProductId] = useState(0);
    const [customerId, setCustomerId] = useState(0);
    const [poDetails, setPoDetails] = useState(null);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isBtnLoader, setIsBtnLoader] = useState(false);

    const moveNextPage = (tabId) => {
        // if (tabId === OrderTabEnum.OrderFinanceAndContact) {
        //     collapesOn();
        // }
        setActiveTab((prev) => prev + 1);
    };
    const movePreviewPage = (tabId) => {
        // if (tabId === OrderTabEnum.OrderItems) {
        //     collapesOff();
        // }
        setActiveTab((prev) => prev - 1);
    };
    const moveToStepOne = () => {
        setActiveTab(0);
        // poUploadRef.current.
        uploadPOStepResetRef.current?.handleResetForm();
        basicInfoResetRef.current?.handleResetForm();
        conatctResetRef.current?.handleResetForm();
    };
    const handleResetOnCustomerChange = () => {
        basicInfoResetRef.current?.handleResetForm();
        conatctResetRef.current?.handleResetForm();
    }
    const addOrder = (data, orderId) => {
        if (data === 0) {
            !orderId && basicInfoResetRef.current?.handleResetForm();
            uploadPOStepRef.current?.handeleAddPoUpload();
        } else if (orderId > 0 && data === 1) {
            !orderId && conatctResetRef.current?.handleResetForm();
            basicInfoRef.current?.handleSaveBasicInfo();
        } else if (data === 2) {
            conatctRef.current?.handlePOContactSave();
        } else if (data === 3) {
            itemRef.current?.AddOrderItem();
        }
    }
    const collapesOn = () => {
        setIsCollapsed(true);
    }
    const collapesOff = () => {
        setIsCollapsed(false);
    }
    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <AddOrderContext.Provider value={{
            uploadPOStepRef, moveNextPage, movePreviewPage, addOrder, activeTab, setActiveTab, orderId, setOrderId, basicInfoRef,
            itemRef, setPoDetails, poDetails, conatctRef, moveToStepOne, customerId, setCustomerId, productId, setProductId,
            conatctResetRef, basicInfoResetRef, uploadPOStepResetRef, isCollapsed, setIsCollapsed, collapesOn, collapesOff, toggleCollapse,
            isBtnLoader, setIsBtnLoader, handleResetOnCustomerChange
        }}>
            {children}
        </AddOrderContext.Provider>
    );
};

AddOrderContextProvider.propTypes = {
    children: PropTypes.node
};