/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import FormCreator from "../../../../components/FinalForms/FormCreator";
import Buttons from "../../../../components/ui/button/Buttons";
import { onResetForm } from "../../../../utils/FormFields/ResetForm/handleResetForm";
import ToastService from "../../../../services/toastService/ToastService";
import { getFieldData, setDropDownOptionField } from "../../../../utils/FormFields/FieldsSetting/SetFieldSetting";
import { SystemConstraintsFormData } from "../config/SystemConstraints.Data";
import { useAddEditSystemConstraintsMutation, useLazyGetSystemConstraintsByIdQuery } from "../../../../app/services/systemConstraintsAPI";
import { useLazyGetAllModulesQuery } from "../../../../app/services/configurationAPI";
import { ApiParametersDataTypes } from "../../../../utils/Enums/commonEnums";
import PropTypes from "prop-types";

const AddEditSystemConstraints = (props) => {
  const systemConstraintsRef = useRef();
  const systemConstraintsId = props.initData?.systemConstraintsId;
  const [systemConstraints, setSystemConstraintsData] = useState(SystemConstraintsFormData);
  const [addEditSystemConstraints,{ isLoading: isAddEditSystemConstraintsLoading,isSuccess: isAddEditSystemConstraintsSuccess,data: AddEditSystemConstraintsData,},] = useAddEditSystemConstraintsMutation();
  const [getSystemConstraintsById,{isFetching: isGetSystemConstraintsByIdFetching,isSuccess: isGetSystemConstraintsByIdSuccess,data: isGetSystemConstraintsByIdData,},] = useLazyGetSystemConstraintsByIdQuery();
  const [getAllModules, { isFetching: isGetAllModulesFetching,isSuccess: isgetAllModulesSucess,data: isGetAllModulesData, }] = useLazyGetAllModulesQuery();
  
  useEffect(() => {
    getAllModules();
  }, []);

  useEffect(() => {
    if (!isGetAllModulesFetching && isgetAllModulesSucess) {
      setDropDownOptionField(isGetAllModulesData, 'moduleId', 'moduleName', SystemConstraintsFormData, 'moduleId');   
    }
}, [isGetAllModulesFetching, isgetAllModulesSucess,isGetAllModulesData]);

  useEffect(() => {
    const dropdownField = getFieldData(SystemConstraintsFormData, "dataType");
    dropdownField.fieldSetting.options = Object.entries(
      ApiParametersDataTypes
    ).map(([key, value]) => ({
      label: key,
      value: value,
    }));
  }, []);
  

  useEffect(() => {
    if (!isGetSystemConstraintsByIdFetching &&isGetSystemConstraintsByIdSuccess &&isGetSystemConstraintsByIdData) {
      if (isGetSystemConstraintsByIdData) {
        let formData = { ...systemConstraints };
        formData.initialState = {
          systemConstraintsId: isGetSystemConstraintsByIdData.systemConstraintsId,
          key :isGetSystemConstraintsByIdData.key,
          value:isGetSystemConstraintsByIdData.value,
          dataType: isGetSystemConstraintsByIdData.dataType,
          moduleId : isGetSystemConstraintsByIdData.moduleId,
          isActive: isGetSystemConstraintsByIdData.isActive,
        }
        setSystemConstraintsData(formData);
      }
    }
  }, [isGetSystemConstraintsByIdFetching,isGetSystemConstraintsByIdSuccess,isGetSystemConstraintsByIdData,]);

  useEffect(() => {
    if (systemConstraintsId && props.isEdit) {
      getSystemConstraintsById(systemConstraintsId);
    }
  }, [systemConstraintsId, props.isEdit]);

  useEffect(() => {
    if (isAddEditSystemConstraintsSuccess && AddEditSystemConstraintsData) {
      if (AddEditSystemConstraintsData.errorMessage.includes("exists")) {
        ToastService.warning(AddEditSystemConstraintsData.errorMessage);
        return;
      }
      props.onSuccess();
      ToastService.success(AddEditSystemConstraintsData.errorMessage);
      onResetData();
      props.onClose();
    }
  }, [isAddEditSystemConstraintsSuccess, AddEditSystemConstraintsData]);

  useEffect(() => {
    if (props.isModelOpen && !props.isEdit) {
      let formData = { ...SystemConstraintsFormData };
      onResetForm(formData, setSystemConstraintsData, null);
    }
  }, [props.isModelOpen]);


  const handleSystemConstraints = () => {
    const formData = systemConstraintsRef.current.getFormData();
    if (formData) {
      const requestData = {
        systemConstraintsId: systemConstraintsId || 0,
        key: formData.key,
        
        dataType: formData.dataType?.value || formData.dataType,
        moduleId: formData.moduleId?.value || formData.moduleId,
        isActive: formData.isActive,
      };
      addEditSystemConstraints(requestData);
    }
  };
  
  const onResetData = () => {
    let formData = { ...SystemConstraintsFormData };
    onResetForm(formData, setSystemConstraintsData, null);
    props.onClose();
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="vertical-form">
            <FormCreator ref={systemConstraintsRef} config={systemConstraints} />
          </div>
        </div>
        <div className="col-md-12 mt-2">
          <div className="d-flex align-item-center justify-content-end">
            <Buttons
              buttonTypeClassName="theme-button"
              buttonText={props.isEdit ? "Update" : "Save"}
              onClick={handleSystemConstraints}
              isLoading={isAddEditSystemConstraintsLoading}
            />
            <Buttons
              buttonTypeClassName="dark-btn ml-5"
              buttonText="Cancel"
              onClick={onResetData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AddEditSystemConstraints.propTypes = {
  isModelOpen: PropTypes.bool,
  isEdit: PropTypes.bool,
  initData: PropTypes.shape({
    systemConstraintsId: PropTypes.number,
  }),
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
}; 

export default AddEditSystemConstraints;
