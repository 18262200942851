import React, { useState } from 'react'
import GridDropdown from '../ui/dropdown/GridDropdown';
import PropTypes from 'prop-types';

const RenderDropdownColumn = ({ rowData, col, rowIndex, onColumnDataChange }) => {

    const [value, setValue] = useState(rowData[col.fieldName]);
    const { colSettings, fieldName } = col;

    /**
     * Handles change in dropdown selection.
     * @param {object|string} selectedOption - The selected option.
     */
    const handleOnChange = (selectedOption) => {
        let selectedValue;

        if (typeof selectedOption === 'object' && selectedOption !== null && 'value' in selectedOption) {
            selectedValue = selectedOption.value;
        } else {
            selectedValue = selectedOption;
        }

        setValue(selectedValue);

        if (onColumnDataChange) {
            const newRowData = { ...rowData, [fieldName]: selectedValue };
            onColumnDataChange(fieldName, newRowData, rowIndex);
        }
    };

    return (
    
       
            <GridDropdown
                placeholder={colSettings?.placeHolder}
                isMultiSelect={col.isMultiSelect}
                options={colSettings.options}
                value={value}
                onChange={handleOnChange}
                onBlur={col.handleOnBlur}
                isDropdownDisabled={colSettings.isDisable}
            />
    
    )
}

RenderDropdownColumn.propTypes = {
    rowData: PropTypes.object.isRequired,  
    col: PropTypes.shape({
        fieldName: PropTypes.string.isRequired,  
        colSettings: PropTypes.shape({
            placeHolder: PropTypes.string,  
            options: PropTypes.array,  
            isDisable: PropTypes.bool,  
        }),
        isMultiSelect: PropTypes.bool,  
        handleOnBlur: PropTypes.func, 
    }).isRequired,
    rowIndex: PropTypes.number.isRequired,  
    onColumnDataChange: PropTypes.func,  
};

export default RenderDropdownColumn