import { validationTypes } from "../../../../../components/FinalForms/libs/data/ValidationTypes";
import { GridColumnType } from "../../../../../components/FinalMolGrid/libs/data/gridColumnType";
import { FormFieldTypes } from "../../../../../data/formFieldType";

export const SnippetListData = {
  initialState: {
    name: "",
    hashtag: "",
    body: "",
    isActive: false
  },
  section: [
    {
      title: "User Information Section",
      row: {},
      style: {
        sectionStyle: "row mb-3",
      },
      fields: [
        {
          id: "name",
          label: "Name",
          Field_Name: "Name",
          fieldType: FormFieldTypes.INPUT,
          dataField: "name",
          fieldSetting: {
            placeholder: "Enter Name",
            allowSpace: true,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-6 col-xl-6 col-md-6 col-12 mb-2 ",
          },
        },
        {
          id: "hashtag",
          label: "Hashtag",
          Field_Name: "Hashtag",
          fieldType: FormFieldTypes.INPUT,
          dataField: "hashtag",
          fieldSetting: {
            allowSpace: true,
            // isDisable:true
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-6 col-xl-6 col-md-6 col-12 mb-2 ",
          },
        },

        {
          id: "body",
          label: "Body",
          Field_Name: "Body",
          fieldType: FormFieldTypes.TEXTEDITOR,
          dataField: "body",
          fieldSetting: {
            placeholder: "Enter Body",
            allowSpace: true,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-xxl-12 col-xl-12 col-md-6 col-12 mb-2 ",
          },
        },
        {
          id: "isActive",
          label: "IsActive",
          Field_Name: "Is Active",
          fieldType: FormFieldTypes.CHECKBOX,
          dataField: "isActive",
          style: {
            containerCss: "col-xxl-12 col-xl-12 col-md-6 col-12 mb-2 ",
          },
        },
      ]
    }
  ]
}

export const SnippedGridConfig = {
  columns: [
    {
      name: "Name",
      fieldName: "name",
      colStyle: {
        width: "30%",
      },
      allowShort: true,
    },
    {
      name: "Hashtag",
      fieldName: "hashtag",
      colStyle: {
        width: "30%",
      },
      allowShort: true,
    },

    {
      name: "IsActive",
      fieldName: "isActive",
      colStyle: {
        width: "20%",
      },
      colType: GridColumnType.CHECKBOX,
      colSettings: {
        isDisabled: true,
      },
    },
    {
      name: "Action",
      colStyle: {
        width: "20%",
      },
      colType: GridColumnType.ACTION,
      defaultAction: {
        allowEdit: true,
        allowDelete: true,
      },
    },
  ],

};