/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../../../../emailManagement/features/comments/Comments.scss";
import PropTypes from "prop-types";
import Comments from "../../../../../emailManagement/features/comments/Comments";
import { useAddOrderCommentsMutation, useLazyGetOrderCommentsByOrderIdQuery } from "../../../../../../app/services/orderAPI";
import ToastService from "../../../../../../services/toastService/ToastService";


const OrderComments = ({  orderId }) => {
  const [newComment, setNewComment] = useState("");
  const [commentsList, setCommentsList] = useState([]);

  const [getCommentsOrder, { isFetching: isCommetsOrderFetching, isSuccess: isCommetsOrderSuccess, data: isCommetsOrderData }] = useLazyGetOrderCommentsByOrderIdQuery();
  const [addCommentsOrder, { isSuccess: isAddCommetsOrderSuccess, data: isAddCommetsOrderData }] = useAddOrderCommentsMutation();

  useEffect(() => {
     if ( orderId) {
      getCommentsOrder(orderId);
    }
  }, [ orderId]);

  useEffect(() => {
    if (!isCommetsOrderFetching && isCommetsOrderSuccess && isCommetsOrderData) {
        setCommentsList(isCommetsOrderData);
    }
  }, [ isCommetsOrderFetching, isCommetsOrderSuccess, isCommetsOrderData]);


  useEffect(() => {
    if (isAddCommetsOrderSuccess && isAddCommetsOrderData) {
      ToastService.success(isAddCommetsOrderData.errorMessage);
    }
  }, [isAddCommetsOrderSuccess, isAddCommetsOrderData]); 


  const handleSubmit = () => {
    if (!newComment.trim()) {
      ToastService.warning("Comment cannot be empty !");
      return;
    }
    if ( orderId) {
        addCommentsOrder({ orderId, comment: newComment }).then(() => {
            getCommentsOrder(orderId);
        });
        }
        setNewComment("");
    };


  return (
    <>
        <Comments comments={commentsList} onhandleSubmit={handleSubmit} setNewComment={setNewComment} newComment={newComment} isCommetsOrderFetching={isCommetsOrderFetching}/>
    </>
  );
};

OrderComments.propTypes = {
  orderId: PropTypes.number,
};

export default OrderComments;
