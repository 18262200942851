import React, { useState } from 'react';
//** Lib's */
import { AppIcons } from '../../../data/appIcons';
import Buttons from '../../../components/ui/button/Buttons';
import CardSection from '../../../components/ui/card/CardSection';
import { OrderStatusEnums } from '../../../utils/Enums/StatusEnums';
import CenterModel from '../../../components/ui/centerModel/CenterModel';
import PendingActionList from './feature/PendingAction/PendingActionOrderList';

//** Compoent's */
const DraftOrderList = React.lazy(() => import("./feature/DraftOrderList"));
const AddOrderTabs = React.lazy(() => import("../AddOrderTabs/addOrderTabs"));
const ApprovedOrderList = React.lazy(() => import("./feature/ApprovedOrderList"));
const PendingApprovalOrderList = React.lazy(() => import("./feature/PendingApproval/PendingApprovalOrderList"));


const OrderList = () => {

  const [activeTab, setActiveTab] = useState("0");
  const [showOrderEntryPopup, setshowOrderEntryPopup] = useState(false);

  const tabs = [
    {
      sMenuItemCaption: "DRAFT",
      component: (
        <div className="mt-2 customer-list-all">
          <DraftOrderList />
        </div>
      ),
    },
    {
      sMenuItemCaption: "PENDING ACTION",
      component: (
        <div className="mt-2 customer-list-all">
          <PendingActionList
            statusId={OrderStatusEnums.PENDINGACTION} />

        </div>
      ),
    },
    {
      sMenuItemCaption: "PENDING APPROVAL",
      component: (
        <div className="mt-2 customer-list-submitted customer-list-all">
          <PendingApprovalOrderList
            statusId={OrderStatusEnums.PENDINGAPPROVAL} />
        </div>
      ),
    },
    {
      sMenuItemCaption: "APPROVED",
      component: (
        <div className="mt-2 customer-list-submitted customer-list-all">
          <ApprovedOrderList />
        </div>
      ),
    },
  ];
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex.toString());
  };
  const handleAddOrderModel = () => {
    setshowOrderEntryPopup(true);
  };
  const closeOrderEntryPopup = () => {
    setshowOrderEntryPopup(false);
  };

  return (
    <React.Fragment>
      <div className="main-customer-grid">
        <div className="row">
          <div className="col-xxl-12 col-xl-12 col-md-12 col-12 other-info-tab main-tab-header">
            <CardSection>
              {tabs?.length > 0 && (
                <div className="row">
                  <div className="col-12">
                    <div className="tab-section mb-0">
                      <div className='d-flex justify-content-between'>
                        <div className="tab-header">
                          {tabs?.map((tab, index) => (
                            <button key={index}
                              className={activeTab === index.toString() ? "active" : ""}
                              onClick={() => handleTabClick(index, tab.sPage)}>
                              {tab.sMenuItemCaption}
                            </button>
                          ))}
                        </div>
                        <Buttons
                          buttonTypeClassName="theme-button icon-btn btn-height"
                          // buttonText="Add Order"
                          imagePath={AppIcons.PlusIcon}
                          textWithIcon={true}
                          onClick={handleAddOrderModel} />
                      </div>
                      {activeTab !== -1 && tabs[activeTab].component && (
                        <div className="tab-content">
                          <div className="tab-body-section">
                            {tabs[activeTab].component}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </CardSection>
          </div>
        </div>
      </div>
      {showOrderEntryPopup && (
        <CenterModel showModal={showOrderEntryPopup} className="center-model po-model" headerClassName="po-model-title"
          handleToggleModal={closeOrderEntryPopup} modalTitle="Order Entry Details"
          modelSizeClass="width-98" >
          <AddOrderTabs closeOrderEntryPopup={closeOrderEntryPopup} />
        </CenterModel>
      )}
    </React.Fragment>
  )
}

export default OrderList